import { forwardRef } from 'react';
import { Input, InputProps } from '@chakra-ui/react';
import { PatternFormat, PatternFormatProps } from 'react-number-format';
import { useController, UseControllerProps } from 'react-hook-form';

export type PhoneInputProps = Partial<PatternFormatProps & InputProps>;

export const PhoneInput = forwardRef((props: PhoneInputProps, ref) => {
    const { format, ...remainigProps } = props;
    return (
        <PatternFormat
            format={format || '(###) ###-####'}
            customInput={Input as any}
            type="tel"
            getInputRef={ref}
            {...remainigProps}
        />
    );
});

export const RhfPhoneInput = (
    props: PhoneInputProps & UseControllerProps<any, any>
) => {
    const { name, control, defaultValue, rules, shouldUnregister, ...inputProps } = props;

    const controller = useController({
        name,
        control,
        defaultValue,
        rules,
        shouldUnregister,
    });

    return (
        <PhoneInput
            {...inputProps}
            name={controller.field.name}
            value={controller.field.value}
            onValueChange={(value) => {
                controller.field.onChange(value.formattedValue);
            }}
            onBlur={controller.field.onBlur}
            ref={controller.field.ref}
        />
    );
};
