import { useQuery } from '@tanstack/react-query';
import type { GetContractSettingsQuery } from 'api-client/types';

import { useApiSdk } from 'ui-core';

export function useContractSettings(templateId?: string) {
    const sdk = useApiSdk();

    const queryResult = useQuery<GetContractSettingsQuery['contractSettings']>({
        queryKey: ['useContractSettings'],
        queryFn: async () => {
            const result = await sdk.GetContractSettings({ templateId: templateId });
            return result.contractSettings;
        },
        staleTime: 5 * 60 * 1000, // no need to fetch on component mount
    });

    return queryResult;
}
