import {
    BaseKey,
    BaseRecord,
    CreateManyResponse,
    CreateResponse,
    CrudFilter,
    CrudFilters,
    CrudSorting,
    CustomResponse,
    DataProvider as DataProviderInterface,
    DeleteManyResponse,
    DeleteOneResponse,
    GetListResponse,
    GetManyResponse,
    GetOneResponse,
    LogicalFilter,
    Pagination,
    MetaDataQuery,
    UpdateManyResponse,
    UpdateResponse,
} from '@pankod/refine-core';
import { GraphQLClient, Variables } from 'graphql-request';
import * as gqlBuilder from 'gql-query-builder';
import pluralize from 'pluralize';

import { getGqlDocument } from 'api-client';

declare module '@pankod/refine-core' {
    interface DataProvider {
        getClient: () => GraphQLClient;
    }
}

export function genereteSort(sort?: CrudSorting) {
    if (sort && sort.length > 0) {
        const sortQuery: Record<string, string> = {};

        sort.forEach((i) => {
            sortQuery[i.field] = i.order.toUpperCase();
        });

        return sortQuery;
    }

    return null;
}

export type FilterProps = {
    filter: Record<string, any>;
    filterOperator?: 'OR' | 'AND';
};

export function generateFilter(filters?: CrudFilters): FilterProps | undefined {
    // Map refine operators to Vendure operators
    const operatorMap: Record<string, string> = {
        eq: 'eq',
        ne: 'notEq',
        lt: 'lt',
        lte: 'lte',
        gt: 'gte',
        gte: 'gte',
        contains: 'contains',
        ncontains: 'notContains',
        in: 'in',
        nin: 'notIn',
        between: 'between',
        null: 'isNull',
    };

    if (!filters) {
        return;
    }

    const filterProps: FilterProps = {
        filter: {},
    };

    const mapFilter = (filter: LogicalFilter) => {
        if (!(filter.operator in operatorMap)) {
            throw new Error(`Filter '${filter.operator}' operator not supported.`);
        }

        const operator = operatorMap[filter.operator];

        filterProps.filter[filter.field] = {
            [operator]: filter.value,
        };
    };

    filters.forEach((filter) => {
        if ('field' in filter) {
            mapFilter(filter);
        } else {
            const value = filter.value as LogicalFilter[];
            filterProps.filterOperator = filter.operator == 'or' ? 'OR' : 'AND';

            filter.value.forEach((subFilter) => {
                if ('field' in subFilter) {
                    mapFilter(subFilter);
                }
            });
        }
    });

    return filterProps;
}

/**
 * Extract the operation result object from a GraphQL response.
 *
 * @param response GraphQL request response
 * @returns Operation result object
 */
function getOperationObject(response: Record<string, any>) {
    // Dynamically find the operation key, because the operation name might not always be known.
    const name = Object.keys(response).filter((key) => key !== '__typename')[0];
    return response[name];
}

export class DataProvider implements DataProviderInterface {
    client: GraphQLClient;

    constructor(client: GraphQLClient) {
        this.client = client;
    }

    getClient = () => {
        return this.client;
    };

    getList = async <TData extends BaseRecord = BaseRecord>(args: {
        
        resource: string;
        pagination?: Pagination;
        hasPagination?: boolean;
        sort?: CrudSorting;
        filters?: CrudFilters;
        metaData?: MetaDataQuery;
        dataProviderName?: string;
    }): Promise<GetListResponse<TData>> => {
        
        const currentPage = args.pagination?.current || 1;
        const pageSize = args.pagination?.pageSize || 100;
        const pageOffset = Math.max(0, currentPage - 1) * pageSize;

        const sortBy = genereteSort(args.sort);
        const filterBy = generateFilter(args.filters);

        const gqlDocument = getGqlDocument(args.metaData?.operation || args.resource);

        let paginationOptions: Record<string, number> = {};
        
        if (args.hasPagination) {
            paginationOptions = {
                skip: pageOffset,
                take: pageSize,
            };
        }

        const variables = {
            options: {
                sort: sortBy,
                ...filterBy,
                ...paginationOptions,
            },
        };

        const response = await this.client.request(gqlDocument, variables);

        let resultObject = getOperationObject(response);

        if (!(resultObject && resultObject.items && resultObject.totalItems !== undefined)) {
            //Calls for the 'settings' values such as channels do not follow the default
            //response structure.  So in that case use map the respone to the items and get the count
            //from the array.  
            if(resultObject && resultObject.length) {
                resultObject = {
                    items: resultObject,
                    totalItems: resultObject.length,
                }
            } else {
                throw new Error(
                    `Invalid response for getList request for '${args.resource}' resource.`
                );
            }
        }

        return {
            data: resultObject.items,
            total: resultObject.totalItems,
        };
    };

    getMany = async <TData extends BaseRecord = BaseRecord>(args: {
        resource: string;
        ids: BaseKey[];
        metaData?: MetaDataQuery;
        dataProviderName?: string;
    }): Promise<GetManyResponse<TData>> => {
        const gqlDocument = getGqlDocument(args.metaData?.operation || args.resource);
        const variables = {
            options: generateFilter([{ field: 'id', operator: 'in', value: args.ids }]),
        };

        const response = await this.client.request(gqlDocument, variables);
        const resultObject = getOperationObject(response);

        if (!(resultObject && resultObject.items)) {
            throw new Error(
                `Invalid response for getMany request for '${args.resource}' resource.`
            );
        }

        return {
            data: resultObject.items,
        };
    };

    getOne = async <TData extends BaseRecord = BaseRecord>(args: {
        resource: string;
        id: BaseKey;
        metaData?: MetaDataQuery;
    }): Promise<GetOneResponse<TData>> => {
        const singularResourceName = pluralize.singular(args.resource);
       
        const gqlDocument = getGqlDocument(args.metaData?.operation || singularResourceName, 'get', true);
      
        const variables = {
            id: args.id,
        };

        const response = await this.client.request(gqlDocument, variables);
        const resultObject = getOperationObject(response);

        return {
            data: resultObject,
        };
    };

    create = async <TData extends BaseRecord = BaseRecord, TVariables = {}>(args: {
        resource: string;
        variables: TVariables;
        metaData?: MetaDataQuery;
    }): Promise<CreateResponse<TData>> => {
        const gqlDocument = getGqlDocument(args.metaData?.operation || args.resource, 'create');

        // Can't be TVariables because we need to add the 'input' property
        
        const variables: Variables = {
            input: args.variables,
        };
        try{
            const response = await this.client.request(gqlDocument, variables);
            const resultObject = getOperationObject(response);

            return {
                data: resultObject,
            };
        } catch(e){
            console.log(e);
            throw(e);
        }
    };

    createMany = async <TData extends BaseRecord = BaseRecord, TVariables = {}>(args: {
        resource: string;
        variables: TVariables[];
        metaData?: MetaDataQuery;
    }): Promise<CreateManyResponse<TData>> => {
        const gqlDocument = getGqlDocument(args.metaData?.operation || args.resource, 'create');

        const response = await Promise.all(
            args.variables.map(async (variables) => {
                const gqlVariables: Variables = {
                    input: variables,
                };

                const response = await this.client.request(gqlDocument, gqlVariables);
                const resultObject = getOperationObject(response);
                return resultObject;
            })
        );

        return {
            data: response,
        };
    };

    update = async <TData extends BaseRecord = BaseRecord, TVariables = {}>(args: {
        resource: string;
        id: BaseKey;
        variables: TVariables;
        metaData?: MetaDataQuery;
    }): Promise<UpdateResponse<TData>> => {
        const gqlDocument = getGqlDocument(args.metaData?.operation || args.resource, 'update');
       
        const variables: Variables = {
            input: args.variables,
        };

        const response = await this.client.request(gqlDocument, variables);
        const resultObject = getOperationObject(response);

        return {
            data: resultObject,
        };
    };

    updateMany = async <TData extends BaseRecord = BaseRecord, TVariables = {}>(args: {
        resource: string;
        ids: BaseKey[];
        variables: TVariables;
        metaData?: MetaDataQuery;
    }): Promise<UpdateManyResponse<TData>> => {
        const gqlDocument = getGqlDocument(args.metaData?.operation || args.resource, 'update');

        const response = await Promise.all(
            args.ids.map(async (id) => {
                const variables: Variables = {
                    input: {
                        ...args.variables,
                        id,
                    },
                };

                const response = await this.client.request(gqlDocument, variables);
                const resultObject = getOperationObject(response);
                return resultObject;
            })
        );

        return {
            data: response,
        };
    };

    deleteOne = async <TData extends BaseRecord = BaseRecord, TVariables = {}>(args: {
        resource: string;
        id: BaseKey;
        variables?: TVariables;
        metaData?: MetaDataQuery;
    }): Promise<DeleteOneResponse<TData>> => {
        // Vendure does not return the entity that was deleted, only the status of the deletion
        const entityResult = await this.getOne<TData>(args);
        
        const gqlDocument = getGqlDocument(args.metaData?.operation || args.resource, 'delete');
        
        let variables = undefined;

        if(args.resource === "orders") {
            variables = {
                orderId: args.id,
            };
        } else {
            variables = {
                id: args.id,
            };
        }

        const response = await this.client.request(gqlDocument, variables as any);
        const resultObject = getOperationObject(response);

        if (resultObject?.result !== 'DELETED') {
            throw new Error(
                resultObject?.message || 'Failed to delete ' + pluralize.singular(args.resource)
            );
        }

        return entityResult;
    };

    deleteMany = async <TData extends BaseRecord = BaseRecord, TVariables = {}>(args: {
        resource: string;
        ids: BaseKey[];
        variables?: TVariables;
        metaData?: MetaDataQuery;
    }): Promise<DeleteManyResponse<TData>> => {
        const response = await Promise.all(
            args.ids.map(async (id) => {
                const response = await this.deleteOne<TData>({
                    resource: args.resource,
                    id: id,
                    metaData: args.metaData,
                });
                return response.data;
            })
        );

        return {
            data: response,
        };
    };

    getApiUrl = (): string => {
        throw new Error('Not implemented');
    };

    custom = async <
        TData extends BaseRecord = BaseRecord,
        TQuery = unknown,
        TPayload = unknown
    >(args: {
        url: string;
        method: 'get' | 'delete' | 'head' | 'options' | 'post' | 'put' | 'patch';
        sort?: CrudSorting;
        filters?: CrudFilter[];
        payload?: TPayload;
        query?: TQuery;
        headers?: {};
        metaData?: MetaDataQuery;
    }): Promise<CustomResponse<TData>> => {
        if (!args.metaData) {
            throw new Error('Missing operation, fields and variables in metaData argument.');
        }

        if (!args.metaData.operation || !args.metaData.fields) {
            throw new Error('Missing properties operation or fields in metaData argument.');
        }

        let customClient = this.client;

        if (args.url) {
            customClient = new GraphQLClient(args.url, { headers: args.headers });
        }

        const operation = args.metaData.operation;
        let queryObject: { query: string; variables: any };

        if (args.method === 'get') {
            queryObject = gqlBuilder.query({
                operation,
                fields: args.metaData.fields,
                variables: args.metaData.variables,
            });
        } else {
            queryObject = gqlBuilder.mutation({
                operation,
                fields: args.metaData.fields,
                variables: args.metaData.variables,
            });
        }

        const response = await customClient.request(queryObject.query, queryObject.variables);

        return {
            data: response[operation],
        };
    };
}

export default DataProvider;
