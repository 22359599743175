import { Button, Heading, Stack } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { useNavigation } from '@pankod/refine-core';

export type ContractFinalizedProps = {
    orderId: string;
    message?: string;
};

export const ContractFinalized = (props: ContractFinalizedProps) => {
    const navMethods = useNavigation();

    const handleClickView = () => {
        navMethods.show('orders', props.orderId as string);
    };

    return (
        <Stack alignItems="center" w="100%" spacing={4} py={10}>
            <CheckCircleIcon w="48px" h="48px" color="green.500" />
            <Heading textAlign="center">{props.message || 'Contract Complete'}</Heading>
            <Button onClick={handleClickView} variant="outline">View Contract</Button>
        </Stack>
    );
};
