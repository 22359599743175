import { Box, Skeleton, Stack } from '@chakra-ui/react';
import { MainSection } from 'ui-core';

export const FormSkeleton = () => {
    return (
        <MainSection variant="form">
            <Box p={8}>
                <Stack>
                    <Skeleton height="48px" />
                    <Skeleton height="48px" />
                    <Skeleton height="48px" />
                </Stack>
            </Box>
        </MainSection>
    );
};
