import { useEffect, useState } from 'react';
import { extendTheme } from '@chakra-ui/react';
import { onThemeModified, getThemeExtension } from '../theme/theme-extension';
import { getBaseTheme } from '../theme/base-theme';

type ThemeType = Record<string, any>;

/**
 * Merges theme objects with base theme and refreshes when registered component styles change.
 *
 * Primarily used to force a re-render after a component module
 * that registers theme styles is hot reloaded with webpack.
 *
 * @param themes One or more theme objects to extend the base theme with.
 * @returns Current theme.
 */
export function useDynamicTheme(...themes: ThemeType[]) {
    const [theme, setTheme] = useState(extendTheme(getBaseTheme(), ...themes));

    useEffect(() => {
        return onThemeModified(() => {
            setTheme(extendTheme(getBaseTheme(), ...themes));
        });
    }, []);

    return theme;
}
