import { useTable, flexRender } from "@pankod/refine-react-table";
import {
    List,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    HStack,
    Text,
    DeleteButton,
} from "@pankod/refine-chakra-ui";

import { ColumnFilter, ColumnSorter, MainSection } from "ui-core";
import {Pagination} from "ui-core"
import { useList, useNavigation } from "@pankod/refine-core";


export const TemplateList: React.FC = () => {
    const navMethods = useNavigation();

    const companyListResult = useList({
        resource: "organization",
        config: {
            sort: [{ order: "asc", field: "name" }],
            pagination: { current: 0, pageSize: 200 },
        },

    });

    const companyListData = companyListResult?.data?.data;

    const columns = [
        {
            id: 'title',
            header: 'Title',
            accessorKey: 'title',
            enableColumnFilter: false,
        },
        {
            id: 'companies',
            header: 'Companies',
            accessorKey: 'companies',
            meta: {
                filterOperator: "contains",
            },
            cell: function render({ getValue }: any) {
                return (
                    getCompanyDisplayString( getValue() as string)
                );
            },
        },
        {
            id: 'states',
            header: 'States',
            accessorKey: 'states',
            meta: {
                filterOperator: "contains",
            },
            cell: function render({ getValue }: any) {
                return (
                    getStateDisplay( getValue() as string)
                );
            },
        },
        
    ];

    const {
        getHeaderGroups,
        getRowModel,
        setOptions,
        refineCore: {
            tableQueryResult: { data: tableData },
            setCurrent,
            pageCount,
            current,
        },
    } = useTable({
        columns,
        refineCoreProps: {
            resource: 'contractTemplates',
            initialSorter: [
                {
                    field: "title",
                    order: "asc",
                    
                },
            ],
        },
    });

    setOptions((prev) => ({
        ...prev,
        meta: {
            ...prev.meta,
            
        },
    }));

    function getStateDisplay(states: string) {
        if(!states) {
            return "";
        }
        let statesArray: string[] = states.split(",");
        if(statesArray && statesArray.length > 0) {
            if(statesArray.length < 5) {
                return states;
            } else if(statesArray.length > 5 && statesArray.length < 50) {
                return statesArray[0] + ", " +statesArray[1] +" +" + (statesArray.length -2);
            } else {
                return "All";
            }

        } else {
            return "N/D";
        }
    }

    function getCompanyDisplayString(companies: string) {
        if(!companies || companies.length < 1) {
            return "N/D";
        } else if(companies === "all") {
            return "All"
        } else {
            let companyList = companies.split(",");
            let displayName = getCompanyName(companyList[0]);
            if(companyList.length > 1){
                displayName += ", +" + (companyList.length -1);
            }
            return displayName;
        }   
    }

    function getCompanyName(id: string) {
        let companyName = "";
        companyListData?.map((company) =>   
        {
            if(company.id === id) {
                companyName = company.name;
            }
        });
        return companyName;
    }
    return (
        <MainSection>
        <List>
            <TableContainer whiteSpace="pre-line">
                <Table variant="simple" colorScheme="gray">
                    <Thead>
                        {getHeaderGroups().map((headerGroup) => (
                            <Tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <Th key={header.id}>
                                        {!header.isPlaceholder && (
                                            <HStack spacing="2">
                                                <Text>
                                                    {flexRender(
                                                        header.column.columnDef
                                                            .header,
                                                        header.getContext(),
                                                    )}
                                                </Text>
                                                <HStack spacing="2">
                                                    <ColumnSorter
                                                        column={header.column}
                                                    />
                                                    <ColumnFilter
                                                        column={header.column}
                                                    />
                                                </HStack>
                                            </HStack>
                                        )}
                                    </Th>
                                ))}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody>
                    {getRowModel().rows.map((row) => (
                            <Tr cursor="pointer" key={row.id}>
                                {row.getVisibleCells().map((cell) => (
                                    <Td key={cell.id} onClick={()=> navMethods.edit("contractTemplates", tableData?.data[row.id as any].id as any)}>
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext(),
                                        )}
                                    </Td>
                                ))}
                                <Td>
                                    <DeleteButton
                                        hideText
                                        size="small"
                                        recordItemId={tableData?.data[row.id as any].id as any}
                                        
                                    />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
            <Pagination
                current={current}
                pageCount={pageCount}
                setCurrent={setCurrent}
            />
        </List>
        </MainSection>
       
    );
};