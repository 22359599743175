type EventCallback = (...args: any[]) => void;

type EventSubscription = {
    callback: EventCallback,
    priority: number,
}

type EventRecord = {
    handlers: EventSubscription[];
}

const events: Record<string, EventRecord> = {};

export class AppEvents {
    public static on(name: string, callback: EventCallback, priority?: number) {
        if (!events[name]) {
            events[name] = {
                handlers: []
            };
        }

        priority = priority || 10;

        events[name].handlers.push({
            callback,
            priority,
        });

        events[name].handlers.sort((a, b) => a.priority - b.priority);
    }
    public static off(name: string, callback: EventCallback) {
        if (events[name]) {
            events[name].handlers = events[name].handlers.filter((handler) => handler.callback !== callback);
        }
    }
    public static async do(name: string, ...args: any[]) {
        let result: any = true;

        if (events[name]) {
            for (let handler of events[name].handlers) {
                try {
                    result = await handler.callback(...args);
                } catch (e) {
                    result = e;
                    break;
                }
            }
        }

        return result === undefined ? true : result;
    }
};

export default AppEvents;
