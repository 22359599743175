import { AuthProvider } from '@pankod/refine-core';
import { GraphQLClient } from 'graphql-request';
import { getHeaders, getClient, getSdk, GqlDocuments } from 'api-client';
import { Permission } from 'api-client/types';
import type { AttemptLoginMutation, AdminResetPasswordMutation, Administrator } from 'api-client/types';

const AUTH_TOKEN_KEY = 'admin-auth-token';
const CHANNEL_TOKEN_KEY = 'admin-channel-token';
const USER_KEY = 'admin-user';

export function getChannelToken() {
    return localStorage.getItem(CHANNEL_TOKEN_KEY);
}

export function setChannelToken(token: string) {
    localStorage.setItem(CHANNEL_TOKEN_KEY, token);
}

export function getAuthToken() {
    return localStorage.getItem(AUTH_TOKEN_KEY);
}

export function getSessionAdmin(): Administrator | null {
    const data = localStorage.getItem(USER_KEY);
    return data ? JSON.parse(data) : data;
}

export function getAuthenticatedClient(apiUrl: string) {
    return getClient(apiUrl, getHeaders(getAuthToken, getChannelToken));
}

export function authProvider(client: GraphQLClient): AuthProvider {
    const sdk = getSdk(client);

    const setSession = async (userData: any, token: string) => {
        if (!('id' in userData)) {
            return;
        }

        localStorage.setItem(AUTH_TOKEN_KEY, token);

        try {
            const adminResponse = await sdk.GetActiveAdministrator();

            localStorage.setItem(
                USER_KEY,
                JSON.stringify(adminResponse.activeAdministrator)
            );
        } catch (e) {
            throw new Error('Failed to fetch current user.');
        }

        const currentChannelToken = getChannelToken();
        const availableChannelTokens = userData.channels.map(
            (channel: any) => channel.token
        );

        if (
            !currentChannelToken ||
            availableChannelTokens.indexOf(currentChannelToken) < 0
        ) {
            setChannelToken(availableChannelTokens[0]);
        }
    }

    return {
        login: async ({ email, password }) => {
            if (email && password) {
                const result = await client.rawRequest<AttemptLoginMutation>(
                    GqlDocuments.ATTEMPT_LOGIN,
                    {
                        username: email,
                        password,
                        rememberMe: true,
                    }
                );

                if (
                    result.data.login.__typename == 'InvalidCredentialsError' ||
                    result.data.login.__typename == 'NativeAuthStrategyError'
                ) {
                    throw new Error(result.data.login.message);
                }

                const token = result.headers.get('vendure-auth-token');

                if (token) {
                    const userData = result.data.login;

                    await setSession(userData, token);

                    return Promise.resolve('/');
                }
            }

            throw new Error('Invalid username or password');
        },
        logout: async () => {
            localStorage.removeItem(CHANNEL_TOKEN_KEY);

            await sdk.LogOut();
            localStorage.removeItem(AUTH_TOKEN_KEY);
            localStorage.removeItem(USER_KEY);
            return Promise.resolve(false);
        },
        forgotPassword: async (props: any) => {
            const result = await sdk.AdminRequestResetPassword({ emailAddress: props.email });

            if (result.requestPasswordReset && 'message' in result.requestPasswordReset) {
                throw new Error(result.requestPasswordReset.message);
            }

            return;
        },
        updatePassword: async (props: any) => {
            const result = await client.rawRequest<AdminResetPasswordMutation>(
                GqlDocuments.ADMIN_RESET_PASSWORD,
                {
                    password: props.password, token: props.token
                }
            );

            if (result.data.resetPassword && 'message' in result.data.resetPassword) {
                throw new Error(result.data.resetPassword.message);
            }

            const authToken = result.headers.get('vendure-auth-token');

            if (authToken) {
                await setSession(result.data.resetPassword, authToken);
            }

            return '/';
        },
        checkError: async (err) => {
            const loginPath = '/login';
            const isGetActiveAdminRequest = err.request?.operationName === 'GetActiveAdministrator';

            // Checks if reqeust is forbidden because user is not logged in
            // and throws to redirect to login path if it is.
            if (err.response && err.response.errors && err.response.errors.length) {
                const errorCode = err.response.errors[0].extensions?.code;

                if (errorCode === 'FORBIDDEN') {
                    if (isGetActiveAdminRequest) {
                        // User is not authenticated
                        throw loginPath;
                    } else {
                        // Could also be because the user doesn't have the needed permission.
                        // Attempt to fetch the current admin to find out for sure.

                        try {
                            const result = await sdk.GetActiveAdministrator();
                            if (!result.activeAdministrator) {
                                // User is not authenticated
                                throw loginPath;
                            }
                        } catch (otherError) {
                            return;
                        }
                    }
                }
            }
        },
        checkAuth: () => {
            const token = getAuthToken();
            if (token) {
                return Promise.resolve();
            }

            return Promise.reject(new Error());
        },
        getPermissions: () => {
            const admin = getSessionAdmin();
            const channelToken = getChannelToken();

            if (!admin || !channelToken) {
                return Promise.reject();
            }

            let permissions: Permission[] = [];

            admin.user.roles.forEach((role) => {
                if (role.channels.find((channel) => channel.token === channelToken)) {
                    permissions = role.permissions;
                }
            });

            return permissions.length ? Promise.resolve(permissions) : Promise.reject();
        },
        getUserIdentity: async (): Promise<Administrator | null> => {
            const admin = getSessionAdmin();
            return admin;
        },
    };
}
