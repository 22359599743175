import {Checkbox, Create, FormControl, FormErrorMessage, FormLabel, Input } from "@pankod/refine-chakra-ui";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { useForm } from "@pankod/refine-react-hook-form";
import { MainSection, RhfPhoneInput } from "ui-core";

export const OrganizationCreate: React.FC<IResourceComponentsProps>  = () => {

    const {
        refineCore: { formLoading },
        saveButtonProps,
        register,
        formState: { errors },  
        control,    
    } = useForm({
        refineCoreProps: { 
            
            errorNotification: (err: any, values: any, resource) => {
                let message = `Content Error`;
                let description = 'Unregistered Error : ' +err?.response?.errors[0]?.message;
                const dupeKeyDescription = 'Company by that name already exists'
                const dupeKeyMessage = 'Please enter a new name';

                if(err.response.errors[0].message.indexOf("duplicate key") >= 0){
                    description = dupeKeyDescription;
                    message = dupeKeyMessage;
                }
                
                return {
                    key: `create-${resource}-notification`,
                    description,
                    message,
                    type: "error",
                }
            } 
        }
    })
    
return (
    <MainSection variant="form">
        <Create title="Create Company" isLoading={formLoading} saveButtonProps={saveButtonProps}>
            <FormControl isRequired mb={2}>
                <FormLabel>Name</FormLabel>
                <Input 
                    id="name"
                    placeholder="Name" 
                    {...register("name", { required: "Name is required" })}
                />
                <FormErrorMessage>
                    {`${errors.title?.message}`}
                </FormErrorMessage>
            </FormControl>
            <FormControl isRequired mb={2}>
                <FormLabel>Email</FormLabel>
                <Input 
                    id="email"
                    placeholder="Email" 
                    type="email"
                    {...register("email", { required: "Email is required" })}
                />
                <FormErrorMessage>
                    {`${errors.title?.message}`}
                </FormErrorMessage>
            </FormControl>
            <FormControl isRequired mb={2}>
                <FormLabel>Phone</FormLabel>
                <RhfPhoneInput
                    id="phone"
                    name="phone"
                    control={control}
                    rules={{ required: 'Required.' }}
                        />
                <FormErrorMessage>
                    {`${errors.title?.message}`}
                </FormErrorMessage>
            </FormControl>
            <FormControl mb={1}>
                <FormLabel>Address</FormLabel>
                <Input 
                    id="address.street"
                    placeholder="Street" 
                    {...register("address.street")}
                />
                <FormErrorMessage>
                    {`${errors.title?.message}`}
                </FormErrorMessage>
            </FormControl>
            <FormControl mb={1}>
                
                <Input 
                    id="address.street2"
                    placeholder="Street 2" 
                    {...register("address.street2")}
                />
                <FormErrorMessage>
                    {`${errors.title?.message}`}
                </FormErrorMessage>
            </FormControl>
            <FormControl mb={1}>
                
                <Input 
                    id="address.city"
                    placeholder="City" 
                    {...register("address.city")}
                />
                <FormErrorMessage>
                    {`${errors.title?.message}`}
                </FormErrorMessage>
            </FormControl>
            <FormControl mb={1}>
                
                <Input 
                    id="address.state"
                    placeholder="State" 
                    {...register("address.state")}
                />
                <FormErrorMessage>
                    {`${errors.title?.message}`}
                </FormErrorMessage>
            </FormControl>
            <FormControl mb={1}>
                
                <Input 
                    id="address.postalCode"
                    placeholder="Zip"
                    type="number" 
                    {...register("address.postalCode")}
                />
                <FormErrorMessage>
                    {`${errors.title?.message}`}
                </FormErrorMessage>
            </FormControl>
            <FormControl mb={2}>
                
                <Input 
                    type="hidden"
                    id="address.country"
                    placeholder="Country"
                    value="USA" 
                    {...register("address.country")}
                />
                <FormErrorMessage>
                    {`${errors.title?.message}`}
                </FormErrorMessage>
            </FormControl>

            <FormControl mb={2}>
            <Checkbox size='lg' colorScheme='green' defaultChecked>
                Auto-Approve Contracts
            </Checkbox>
                
            </FormControl>
            
        </Create>
    </MainSection>
);
};