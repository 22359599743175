import { useState } from 'react';
import {
    Box,
    Button,
    Flex
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useNotification } from '@pankod/refine-core';

import AppEvents from '../../app-events';
import { useStepData, useStepDispatch } from './step-form';

export const ActionBar = ({ children, onSubmit, submitLabel, isLoading, isDisabled }: any) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const notification = useNotification();
    const stepData = useStepData();
    const dispatch = useStepDispatch();
    const currentStepId = stepData.steps[stepData.currentStep].id;

    if (!submitLabel) {
        submitLabel = 'Continue';
    }

    const handleClickContinue = async () => {
        setIsSubmitting(true);

        try {
            let eventResult = await AppEvents.do('before_submit_step', currentStepId, stepData);

            if (eventResult instanceof Error) {
                setIsSubmitting(false);
                return;
            }

            if (typeof onSubmit == 'function') {
                await onSubmit(currentStepId, stepData);
            }

            setIsSubmitting(false);

            window.scrollTo({ top: 0, behavior: 'smooth' });

            dispatch.submitStep();
        } catch (e: any) {
            setIsSubmitting(false);

            let errorDescription = e.message;

            if ('response' in e) {
                const errorCode = e.response.errors[0]?.extensions?.code;

                if (errorCode) {
                    errorDescription = errorCode == 'BAD_USER_INPUT' ? 'Invalid input.' : 'Code: ' + errorCode;
                } else if (e.response.errors[0]?.message) {
                    errorDescription = e.response.errors[0]?.message;
                }
            }

            notification.open && notification.open({
                type: 'error',
                message: 'Error',
                description: errorDescription,
            });
        }
    };

    const handleClickBack = () => {
        dispatch.stepBack();
    };

    const backButton = <Button variant="link" leftIcon={<ArrowBackIcon />} px={0} onClick={handleClickBack}>Back</Button>;
    const continueButton = <Button onClick={handleClickContinue} isLoading={isSubmitting || isLoading} disabled={isDisabled}>{submitLabel}</Button>;

    return (
        <Flex justifyContent="space-between" alignItems="center" width="100%">
            {typeof children == 'function' ? (
                children({
                    backButton: stepData.currentStep > 0 ? backButton : <Box />,
                    continueButton,
                    currentStepId,
                    stepData,
                })
            ) : (
                <>
                    {stepData.currentStep > 0 ? backButton : <Box />}
                    {continueButton}
                </>
            )}
        </Flex>
    );
};

export default ActionBar;
