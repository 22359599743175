import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import type {
    CreateIntegrationMutation,
    CreateIntegrationInput,
    DeleteIntegrationMutation,
    GetIntegrationsQuery,
    IntegrationListOptions,
} from 'api-client/types';

import { useApiSdk } from './use-api-sdk';

export function useIntegrations(params: IntegrationListOptions) {
    const sdk = useApiSdk();

    const queryResult = useQuery<GetIntegrationsQuery['integrations']>({
        queryKey: ['listIntegrations', params],
        queryFn: async () => {
            const result = await sdk.GetIntegrations({
                options: params,
            });
            return result.integrations;
        },
        staleTime: 5 * 60 * 1000, // no need to fetch on component mount
    });

    return queryResult;
}

export function useCreateIntegration(
    onSuccess?: (result: CreateIntegrationMutation['createIntegration']) => void
) {
    const sdk = useApiSdk();
    const queryClient = useQueryClient();

    return useMutation<
        CreateIntegrationMutation['createIntegration'],
        Error,
        CreateIntegrationInput
    >({
        mutationFn: async (vars) => {
            const response = await sdk.CreateIntegration({
                input: vars,
            });

            return response?.createIntegration;
        },
        onSuccess: (response) => {
            queryClient.invalidateQueries(['listIntegrations']);

            typeof onSuccess === 'function' && onSuccess(response);
        },
    });
}

export function useDeleteIntegration(
    onSuccess?: (result: DeleteIntegrationMutation['deleteIntegration']) => void
) {
    const sdk = useApiSdk();
    const queryClient = useQueryClient();

    return useMutation<DeleteIntegrationMutation['deleteIntegration'], Error, string>({
        mutationFn: async (id) => {
            const response = await sdk.DeleteIntegration({
                id,
            });

            return response?.deleteIntegration;
        },
        onSuccess: (response) => {
            queryClient.invalidateQueries(['listIntegrations']);

            typeof onSuccess === 'function' && onSuccess(response);
        },
    });
}
