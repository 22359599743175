import React from 'react';
import { Box, useMultiStyleConfig } from '@chakra-ui/react';

import { registerComponentStyle } from '../../theme/theme-extension';

const themeKey = 'CardSection';

export type CardSectionProps = {
    title?: string;
    variant?: 'nopadding' | 'default';
    children?: React.ReactNode;
};

export const CardSection = (props: CardSectionProps) => {
    const themeStyles = useMultiStyleConfig(themeKey, { variant: props.variant });

    return (
        <Box __css={themeStyles.container}>
            {!!props.title && (
                <Box __css={themeStyles.heading}>
                    <Box as="h2" __css={themeStyles.title}>{props.title}</Box>
                </Box>
            )}
            <Box __css={themeStyles.content}>{props.children}</Box>
        </Box>
    );
};

registerComponentStyle(themeKey, {
    baseStyle: {
        container: {
            background: 'white',
            border: '1px',
            borderColor: 'gray.200',
            borderRadius: 'lg',
        },
        heading: {
            borderBottom: '1px',
            borderColor: 'blackAlpha.100',
            px: 4,
            py: 4,
        },
        title: {
            fontWeight: 'normal',
            fontSize: 'lg',
        },
        content: {
            px: 4,
            py: 4,
        },
    },
    variants: {
        nopadding: {
            content: {
                px: 0,
                py: 0,
            },
        },
    },
});

export default CardSection;
