import {
    Button,
    Edit,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    Stack,
    useDisclosure
} from "@pankod/refine-chakra-ui";
import { IResourceComponentsProps, useSelect } from "@pankod/refine-core";
import { useForm } from "@pankod/refine-react-hook-form";
import { useEffect, useState } from "react";
import { MainSection, IChannelSelect, useApiSdk } from "ui-core";
import { Branding } from "ui-rto";

export const AdminEdit: React.FC<IResourceComponentsProps> = () => {
    
    const { isOpen, onOpen, onClose } = useDisclosure();
    const sdk = useApiSdk();
    
    const onResetPasswordClick = () => {
        onOpen();
    }

    const [newPassword, setNewPassword] = useState("");
    const [selectedRole, setSelectedRole] = useState("");

    const onRoleChange = (event: any) => {
        setSelectedRole(event.target.value as string);
    }

    const onNewPasswordChange = (event: any) => {
        setNewPassword(event.target.value as string);
    }

    const onSavepasswordClick = () => {
        if(newPassword?.length > 0){
            onClose();
            doChangePassword();
        }
    }

    async function doChangePassword() {
        try {
            const result = await sdk.UpdateAdministrator({
                input : {
                    id: postData?.id as any,
                    password: newPassword,
                }
            })
        } catch(error){
            console.error(error);
        }
    }
    
    

    const {
        refineCore: {  queryResult },
        saveButtonProps,
        register,
        formState: { errors },      
    } = useForm({
        refineCoreProps: { 
            onMutationError: (error) => console.log('called: ' + error),
            errorNotification: (err: any, values, resource) => {
                
                let description = 'Custom description';
                const dupeKeyDescription = 'User with that email address already exists'
                
                if(err.response.errors[0].message.indexOf("duplicate key") >= 0){
                     description = dupeKeyDescription;
                 }
                const message = `Error`;
                return {
                    key: `create-${resource}-notification`,
                    description,
                    message,
                    type: "error",
                }
            } 
        }
    });
    

    const postData = queryResult?.data?.data;

    useEffect(() => {
            setSelectedRole(postData?.user?.roles[0]?.id);
    }, [postData] );
    
    const {options} = useSelect<IChannelSelect>({
        resource: "role",
        optionLabel: "description",
        optionValue: "id",
        filters: [
            {
                field: "code",
                operator: "ne",
                value: "__customer_role__",
            }
        ],
        sort:[
            {
                field: "id",
                order: "asc",
            }
        ]
    });

return (
    <MainSection variant="form">
       
        <Edit
            canDelete
            deleteButtonProps={{ colorScheme: "orange" }}
            saveButtonProps={saveButtonProps}
            
            headerButtons={() => (
                <HStack> 
                    <Button colorScheme="blue" onClick={onResetPasswordClick}>Reset Password</Button>
                </HStack>
            )}
        >
            <Stack spacing={4}>
                <FormControl isRequired>
                    <Input 
                        type="hidden" 
                        id="id" 
                        defaultValue={postData?.id}
                        {...register("id")}
                        />
                    <FormLabel>First Name</FormLabel>
                    <Input 
                        id="firstName"
                        placeholder="First Name"
                        defaultValue={queryResult?.data?.data.firstName}
                        {...register("firstName", { required: "First Name is required" })}
                    />
                    <FormErrorMessage>
                        {`${errors.title?.message}`}
                    </FormErrorMessage>
                </FormControl>
                <FormControl isRequired>
                    <FormLabel>Last Name</FormLabel>
                    <Input 
                        id="lastName"
                        placeholder="Last Name" 
                        {...register("lastName", { required: "Last Name is required" })}
                    />
                    <FormErrorMessage>
                        {`${errors.title?.message}`}
                    </FormErrorMessage>
                </FormControl>
                <FormControl isRequired>
                    <FormLabel>Email</FormLabel>
                    <Input 
                        id="emailAddress"
                        placeholder="Email" 
                        type="email"
                        {...register("emailAddress", { required: "Email is required" })}
                    />
                    <FormErrorMessage>
                        {`${errors.title?.message}`}
                    </FormErrorMessage>
                </FormControl>
                

                <FormControl isRequired>
                    <FormLabel>Role</FormLabel>
                    <Select 
                        id="roleIds" 
                        placeholder='Select Role'
                        value={selectedRole}
                        
                        {...register("roleIds.0", { 
                            required: "Role is required",
                            onChange: onRoleChange, 
                        })}
                        >
                            {options?.map((channel) => 
                                <option key={channel.label} value={channel.value}>
                                    { channel.label.indexOf("_default") > -1 ? Branding.name : channel.label }
                                </option>
                            )}
                        
                    </Select>
                </FormControl>
            </Stack>
        </Edit>
        <Modal blockScrollOnMount={true} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
            <ModalHeader>Change Password</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <FormControl >
                        <Input 
                            placeholder='New Password' 
                            onChange={onNewPasswordChange}
                        />
                    </FormControl>
                    
                </ModalBody>
                <ModalFooter>
                        
                        <Button colorScheme="red" onClick={onClose} mr={3}>
                            Cancel
                        </Button>
                        <Button colorScheme='green' onClick={onSavepasswordClick}>
                            Save
                        </Button>
                    </ModalFooter>
            </ModalContent>
        </Modal>
    </MainSection>
);
};