import { ComponentStyleConfig, ChakraTheme, Theme } from '@chakra-ui/react';
import { Subscriber, SimpleObserver } from '../simple-observer';

type ComponentStyles = Record<string, ComponentStyleConfig>;

const componentStyles = new SimpleObserver<ComponentStyles>({});

export function registerComponentStyle(componentName: string, style: ComponentStyleConfig) {
    componentStyles.value = { ...componentStyles.value, [componentName]: style };
}

export function getComponentStyle(componentName: string) {
    return componentStyles.value[componentName];
}

export function getThemeExtension(): Record<string, any> {
    return {
        components: {
            ...componentStyles.value,
        },
    };
}

export function onThemeModified(callback: Subscriber<ComponentStyles>) {
    return componentStyles.subscribe(callback);
}
