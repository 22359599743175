import { useEffect, useState } from 'react';
import {
    Alert,
    Box,
    Button,
    Card,
    CardBody,
    Code,
    FormControl,
    FormLabel,
    Heading,
    IconButton,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Skeleton,
    Stack,
    TableContainer,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    useDisclosure,
} from '@chakra-ui/react';
import type { ModalProps } from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';

import type { GetOrganizationQuery } from 'api-client/types';
import {
    useIntegrations,
    useCreateIntegration,
    useDeleteIntegration,
} from '../../hooks/use-integrations';

export const CreateIntegrationModal = (
    props: Omit<
        ModalProps & { organization: NonNullable<GetOrganizationQuery['organization']> },
        'children'
    >
) => {
    const [name, setName] = useState('');
    const [clientId, setClientId] = useState('');
    const [clientSecret, setClientSecret] = useState('');

    const createMutation = useCreateIntegration((result) => {
        setClientId(result.user.identifier);
        setClientSecret(result.secretKey || '');
    });

    useEffect(() => {
        if (props.isOpen) {
            // reset on open
            setName('');
            setClientId('');
            setClientSecret('');
        }
    }, [props.isOpen]);

    const handleCreate = async () => {
        createMutation.mutate({
            name,
            organizationId: props.organization.id,
        });
    };

    const modalContentCreate = (
        <>
            <FormControl id="name">
                <FormLabel>Name</FormLabel>
                <Input
                    type="text"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </FormControl>
        </>
    );

    const modalContentShow = (
        <>
            <Alert status="warning" mb={4}>
                Be sure to copy the the client secret! It cannot be shown again.
            </Alert>
            <Stack spacing="3">
                <Box>
                    <Box mb={1} fontWeight={'semibold'}>
                        Client ID
                    </Box>
                    <Box>
                        <Code colorScheme="gray">{clientId}</Code>
                    </Box>
                </Box>
                <Box>
                    <Box mb={1} fontWeight={'semibold'}>
                        Client Secret
                    </Box>
                    <Box>
                        <Code colorScheme="gray">{clientSecret}</Code>
                    </Box>
                </Box>
                <Box>
                    <Box mb={1} fontWeight={'semibold'}>
                        Channel Token
                    </Box>
                    <Box>
                        <Code colorScheme="gray">{props.organization.channel.token}</Code>
                    </Box>
                </Box>
            </Stack>
        </>
    );

    return (
        <Modal {...props}>
            <ModalOverlay />

            <ModalContent>
                <ModalHeader>{clientId ? 'Integration Created' : 'Add Integration'}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>{clientId ? modalContentShow : modalContentCreate}</ModalBody>
                <ModalFooter>
                    {clientId ? (
                        <Button onClick={props.onClose}>Done</Button>
                    ) : (
                        <>
                            <Button onClick={props.onClose} mr={3} variant="ghost">
                                Cancel
                            </Button>
                            <Button onClick={handleCreate} isLoading={createMutation.isLoading}>
                                Create
                            </Button>
                        </>
                    )}
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

type IntegrationsBoxProps = {
    organization?: GetOrganizationQuery['organization'];
};

export const IntegrationsBox = (props: IntegrationsBoxProps) => {
    const {
        isOpen: isCreateModalOpen,
        onOpen: openCreateModal,
        onClose: onCreateModalClose,
    } = useDisclosure();
    const integrationsQuery = useIntegrations({
        filter: {
            organizationId: { eq: props.organization?.id || '' },
        },
        take: 10,
    });
    const deleteIntegrationMutation = useDeleteIntegration();

    let channelToken = props?.organization?.channel.token || '';

    const handleClickAddIntegration = () => {
        openCreateModal();
    };

    const onClickDelete = (id: string) => {
        return () => {
            const result = confirm(
                'Are you sure? Any application using this integration will no longer be connected.'
            );
            if (result) {
                deleteIntegrationMutation.mutate(id);
            }
        };
    };

    let table = <></>;

    if (integrationsQuery.data?.items.length) {
        table = (
            <Table size="sm" variant="simple" colorScheme="gray">
                <Thead>
                    <Tr>
                        <Th>Name</Th>
                        <Th>Client ID</Th>
                        <Th></Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {integrationsQuery.data.items.map((integration) => (
                        <Tr
                            key={integration.id}
                            _hover={{ bg: 'gray.100' }}
                            onClick={() => {
                                console.log('clicked');
                            }}
                        >
                            <Td>{integration.name}</Td>
                            <Td>{integration.user.identifier}</Td>
                            <Td textAlign="right">
                                <IconButton
                                    icon={<DeleteIcon />}
                                    aria-label="Delete"
                                    variant="ghost"
                                    colorScheme="red"
                                    onClick={onClickDelete(integration.id)}
                                    isLoading={deleteIntegrationMutation.isLoading}
                                />
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        );
    } else if (integrationsQuery.data?.items.length === 0) {
        table = <Box>No results.</Box>;
    } else if (integrationsQuery.isLoading) {
        table = <Skeleton height="48px" />;
    }

    return (
        <Card>
            <CardBody>
                <Heading size="md" mb={4}>
                    Integrations
                </Heading>
                <TableContainer>{table}</TableContainer>
                <Button colorScheme="blue" mr={3} my={3} onClick={handleClickAddIntegration}>
                    Add Integration
                </Button>
                <Box py={4}>
                    Channel Token: <Code colorScheme="gray">{channelToken}</Code>
                </Box>
                {props.organization && (
                    <CreateIntegrationModal
                        isOpen={isCreateModalOpen}
                        onClose={onCreateModalClose}
                        organization={props.organization}
                    />
                )}
            </CardBody>
        </Card>
    );
};
