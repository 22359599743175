import { useOne, useHandleNotification, useLogout } from '@pankod/refine-core';

import type { Administrator } from 'api-client/types';

export function useCurrentAdmin() {
    // Makes a DataProvider request to get current admin instead of getting cached admin from local storage
    // to ensure user is logged in and to redirect to login page if not.

    const handleNotification = useHandleNotification();
    const logoutMutation = useLogout();

    const query = useOne<Administrator>({
        resource: 'GET_ACTIVE_ADMINISTRATOR',
        id: 0, // Not used for this query, but useOne expects it
        queryOptions: {
            retry: false,
            onSuccess: (data) => {
                // If this query returns null, it means the user is not logged in.

                if (!data.data) {
                    handleNotification({
                        message: 'Invalid session.',
                        description: 'Please log in again.',
                        type: 'error',
                    });

                    logoutMutation.mutate();
                }
            },
        },
        errorNotification: {
            message: 'Failed to fetch current user.',
            type: 'error',
        },
    });

    return query;
}
