import React from 'react';

import { createContextDataStore, DataStoreProviderProps } from '../../context-data-store';

export type StepInterface = {
    id: string;
    label: string;
    position?: number;
    component: () => React.ReactElement | null;
};

type StepFormContextInterface = {
    currentStep: number;
    steps: StepInterface[];
};

const stepDataStore = createContextDataStore<StepFormContextInterface>({
    submitStep: (data) => {
        if (data.currentStep + 1 < data.steps.length) {
            return {
                ...data,
                currentStep: data.currentStep + 1,
            };
        }

        return data;
    },

    goToStep: (data, stepId: string) => {
        for (let i = 0; i < data.steps.length; i++) {
            if (data.steps[i].id === stepId) {
                return {
                    ...data,
                    currentStep: i,
                };
            }
        }

        return data;
    },

    stepBack: (data) => {
        if (data.currentStep - 1 >= 0) {
            return {
                ...data,
                currentStep: data.currentStep - 1,
            };
        }

        return data;
    },

    setSteps: (data, steps: StepInterface[]) => {
        return {
            ...data,
            steps,
        };
    },
});

export const useStepData = stepDataStore.useData;
export const useStepDispatch = stepDataStore.useDispatch;

export type StepFormProps = DataStoreProviderProps<StepFormContextInterface>;

const StepComponentController = () => {
    const stepData = useStepData();
    const currentStep = stepData.steps[stepData.currentStep];
    const StepComponent = currentStep.component;

    return <StepComponent />;
};

export const StepForm = (props: StepFormProps) => {
    return (
        <stepDataStore.Provider initialData={props.initialData}>
            {props.children}
            <StepComponentController />
        </stepDataStore.Provider>
    );
};
