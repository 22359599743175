import { forwardRef } from 'react';
import {
    Input,
    InputProps,
    InputGroup,
    InputLeftElement,
    forwardRef as chForwardRef,
} from '@chakra-ui/react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { useController, UseControllerProps } from 'react-hook-form';

export type MoneyInputProps = Omit<NumericFormatProps, 'size'> & InputProps;

const InputWithCurrencyLabel = chForwardRef((props: InputProps, ref) => {
    return (
        <InputGroup size={props.size}>
            <InputLeftElement pointerEvents="none" color="gray.300" fontSize={props.size == 'sm' ? '1em' : '1.2em'} children="$" />
            <Input ref={ref} {...props} />
        </InputGroup>
    );
});

export const MoneyInput = forwardRef((props: MoneyInputProps, ref) => {
    const { size, ...remainingProps } = props;

    return (
        <NumericFormat
            customInput={InputWithCurrencyLabel as any}
            getInputRef={ref}
            thousandSeparator={true}
            allowNegative={false}
            decimalScale={2}
            fixedDecimalScale={true}
            size={size as any}
            {...remainingProps}
        />
    );
});

export const RhfMoneyInput = (props: MoneyInputProps & UseControllerProps<any, any>) => {
    const { name, control, defaultValue, rules, shouldUnregister, ...inputProps } = props;

    const controller = useController({
        name,
        control,
        defaultValue,
        rules,
        shouldUnregister,
    });

    return (
        <MoneyInput
            {...inputProps}
            name={controller.field.name}
            value={controller.field.value}
            onValueChange={(value) => {
                controller.field.onChange(value.floatValue);
            }}
            onBlur={controller.field.onBlur}
            ref={controller.field.ref}
        />
    );
};
