import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
    Box,
    Flex,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Input,
    Radio,
    RadioGroup,
    Stack,
    VStack,
} from '@chakra-ui/react';

import { ActionBar, RhfPhoneInput, useApiSdk } from 'ui-core';
import {
    useRtoOrderFormData,
    useInvalidateRtoOrderData,
} from '../../../hooks/use-rto-order-form-data';

export const StepRenterInfo = () => {
    const pageQuery = useRtoOrderFormData();
    const invalidate = useInvalidateRtoOrderData();
    const sdk = useApiSdk();
    const order = pageQuery.data?.order;
    const orderFields = order?.rtoContract?.fields;

    const [propertyType, setPropertyType] = useState(
        orderFields?.is_owner === false ? 'rent' : 'own'
    );

    const initialValues = {
        property_type: propertyType,
        landlord: orderFields?.landlord || '',
        landlord_phone: orderFields?.landlord_phone || '',
    };

    const form = useForm({ defaultValues: initialValues });
    const register = form.register;
    const errors = form.formState.errors;

    const onSubmit = async (formData: any) => {
        const result = await sdk.SetRtoAppFields({
            input: {
                orderId: order?.id || '',
                fields: {
                    is_owner: formData.property_type != 'rent' ? true : false,
                    landlord: formData?.landlord || '',
                    landlord_phone: formData?.landlord_phone || '',
                },
            },
        });

        if (result.setRtoAppFields?.id) {
            invalidate(result.setRtoAppFields?.id);
        }
    };

    const handleClickContinue = async () => {
        return form.handleSubmit(onSubmit, () => {
            throw new Error('Invalid form.');
        })();
    };

    return (
        <Box>
            <VStack width="100%" alignItems="start" spacing={10} color="gray.700">
                <Box w="100%">
                    <Controller
                        name="property_type"
                        control={form.control}
                        render={(props) => {
                            return (
                                <RadioGroup
                                    value={props.field.value}
                                    onChange={(value) => {
                                        setPropertyType(value);
                                        props.field.onChange(value);
                                    }}
                                >
                                    <Stack spacing={4}>
                                        <Radio value="own" size="lg">
                                            Property is owned by applicant
                                        </Radio>
                                        <Radio value="rent" size="lg">
                                            Property is rented by applicant
                                        </Radio>
                                    </Stack>
                                </RadioGroup>
                            );
                        }}
                    />

                    {propertyType != 'own' && (
                        <Flex mt={8} w="100%">
                            <FormControl flex="1" mr={2} isInvalid={!!errors.landlord}>
                                <FormLabel>Landord</FormLabel>
                                <Input {...register('landlord', { required: 'Required.' })} />
                                <FormErrorMessage>{errors.landlord?.message}</FormErrorMessage>
                            </FormControl>
                            <FormControl flex="1" ml={2} isInvalid={!!errors.landlord_phone}>
                                <FormLabel>Landlord phone</FormLabel>
                                <RhfPhoneInput
                                    name="landlord_phone"
                                    control={form.control}
                                    rules={{ required: 'Required.' }}
                                />
                                <FormErrorMessage>
                                    {errors.landlord_phone?.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Flex>
                    )}
                </Box>
                <ActionBar onSubmit={handleClickContinue} />
            </VStack>
        </Box>
    );
};

export default StepRenterInfo;
