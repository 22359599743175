export type Subscriber<T> = (newValue: T, previousValue?: T) => void;

/**
 * Stores a value and notifies subscribers when the value is changed.
 */
export class SimpleObserver<T> {
    private observedValue: T;
    private subscribers: Subscriber<T>[] = [];

    constructor(initialValue: T) {
        this.observedValue = initialValue;
    }

    public get value(): T {
        return this.observedValue;
    }

    public set value(newValue: T) {
        if (newValue === this.observedValue) {
            return;
        }

        const previousValue = this.observedValue;
        this.observedValue = newValue;

        this.subscribers.forEach((subscriber) => {
            subscriber(newValue, previousValue);
        });
    }

    /**
     * Subscribe to changes of the observed value.
     *
     * @param subscriber
     * @returns Unsubscribe method.
     */
    public subscribe(subscriber: Subscriber<T>) {
        if (this.subscribers.indexOf(subscriber) < 0) {
            this.subscribers.push(subscriber);
        }

        return () => {
            this.subscribers = this.subscribers.filter((fn) => fn !== subscriber);
        };
    }
}

export default SimpleObserver;
