import { Button, HStack, Show, Text, Heading, StackDivider, EmailField, VStack, Table, TableContainer, Tbody, Td, Th, Thead, Tr, Circle, Center, useDisclosure, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select } from "@pankod/refine-chakra-ui";
import { Card, CardBody } from '@chakra-ui/react'
import { IResourceComponentsProps, useList, useNavigation, useSelect, useShow } from "@pankod/refine-core";
import {  IChannelSelect, IntegrationsBox, MainSection, useApiSdk } from "ui-core";
import { IconFileCheck, IconMail, IconMap, IconPhoneCall, IconPigMoney } from "@tabler/icons";
import type { GetOrganizationQuery } from 'api-client/types';

import { useState } from "react";


interface SimpleUser {
    firstName: string,
    lastName: string,
    id: string,
    role: string,
}

const emailInput = "email";
const firstNameInput = "firstName";
const lastNameInput = "lastName";
const passwordInput = "password";


export const OrganizationShow: React.FC<IResourceComponentsProps> = () => {

    const navMethods = useNavigation();
    
    const onContractClick = (id: string) => {
        navMethods.edit("contractTemplates", id);
    }
    const sdk = useApiSdk();

    const { queryResult } = useShow();
    const { data } = queryResult;

    function formatPhoneNumber(phoneNumberString: string) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return null;
    }
    let userList: SimpleUser[] = [];
    const [adminList, setAdminList] = useState(userList);
    const [adminListLoaded, setAdminListLoaded] = useState(0);

    loadUsers(false);

    function loadUsers(isRefresh: boolean) {
        if(adminListLoaded < 3 || isRefresh) {
            LoadAdminUsers();
        }
    }

    async function LoadAdminUsers() {
        try {
            const result = await sdk.GetAdministrators({});

            //todo: need to filter this on the call
            let adminList: SimpleUser[] = [];
            result.administrators.items.map((admin) => {
                let roleDescription = admin.user?.roles[0]?.description as string;
                    if( roleDescription.indexOf(data?.data.name) > -1) {
                        let user: SimpleUser = {
                            firstName: admin.firstName as string,
                            lastName: admin.lastName as string,
                            id: admin.id as string,
                            role: roleDescription,
        
                        }
                        adminList.push(user);
                    }
                    
            });
            setAdminList(adminList);
            
            if(adminList) {
                let count = adminListLoaded +1
                setAdminListLoaded(count);
            }
        } catch(error) {
            console.error(error);
        }
    }

    
   
    function newUserClick() {
        onOpen();
    }

    const [newUserFirst, setnewUserFirst] = useState("");
    const [newUserLast, setnewUserLast] = useState("");
    const [newUserEmail, setnewUserEmail] = useState("");
    const [newUserPassword, setnewUserPassword] = useState("");
    const [newUserRole, setnewUserRole] = useState("");

    const onNewUserModalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputId = event.target.id as string;
        const inputVal = event.target.value as string;
        switch (inputId) {
            case firstNameInput:
               
                setnewUserFirst(inputVal);
                break;
            case lastNameInput:
                
                setnewUserLast(inputVal);
                break;
            case emailInput:
                
                setnewUserEmail(inputVal);
                break;
            case passwordInput:
                
                setnewUserPassword(inputVal);
                break;
        }
    }

    const onNewUserRoleModalChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setnewUserRole(event.target.value as string);
    }

    function clearVarState() {
        setnewUserFirst("");
        setnewUserLast("");
        setnewUserEmail("");
        setnewUserPassword("");
        setnewUserRole("");
    }

    function onSaveNewUserClick() {
        if(newUserFirst?.length < 1) {
            console.error("first");
        } else if(newUserLast?.length < 1) {
            console.error("last");
        } else if(newUserEmail?.length < 1) {
            console.error("email");
        } else if(newUserPassword?.length < 1) {
            console.error("password");
        } else if(newUserRole?.length < 1) {
            console.error("role");
        } else {
            doAddUser();
        }
        onClose();
    }

    async function doAddUser() {
        try {
            const result = await sdk.CreateAdministrator({
                input: {
                    firstName: newUserFirst,
                    lastName: newUserLast,
                    emailAddress: newUserEmail,
                    password: newUserPassword,
                    roleIds: [newUserRole]
                }
            });
            clearVarState();
            loadUsers(true);

        }catch(error) {
            console.error(error);
        }
    }

    const templateCompanyList = useList({
        resource: "contractTemplates",
        config: {
            pagination: { current: 0, pageSize: 200 },
            filters: [
                {
                    field: "companies",
                    operator: "contains",
                    value: data?.data?.id,
                },
            ],
        },

    });

    const templateAllList = useList({
        resource: "contractTemplates",
        config: {
            pagination: { current: 0, pageSize: 200 },
            filters: [
                {
                    field: "companies",
                    operator: "contains",
                    value: "all",
                },
            ],
        },

    });
    
    const {options} = useSelect<IChannelSelect>({
        resource: "role",
        optionLabel: "description",
        optionValue: "id",
        sort:[
            {
                field: "id",
                order: "asc",
            }
        ],
        filters: [
            {
                field: "description",
                operator: "contains",
                value: data?.data.name,
            }
        ],
    });

    const { isOpen, onOpen, onClose } = useDisclosure();

return (
    <MainSection >
        <Show
            title={data?.data.name}
            canDelete
            headerButtons>

            <Card mb={4}>
                <CardBody>
                <Heading size='md' mb={3}>Contact Details</Heading>
                    <HStack divider={<StackDivider />} spacing='4' >
                        <VStack spacing="3" alignItems="start">
                            <HStack spacing='1' alignItems="start">
                                
                                <Circle size="40px" bg="green" color="white">
                                    <IconPhoneCall color="white"/>
                                </Circle>
                                <Text pt='2' fontSize='md'>
                                    {formatPhoneNumber(data?.data.phone as string)}
                                </Text>
                                
                            </HStack>
                            <HStack spacing='1' alignItems="start">
                                <Circle size="40px" bg="green" color="white">
                                    <IconMail/>
                                </Circle>
                                <Text pt='2' fontSize='md'>
                                    <EmailField value={data?.data.email as string} />
                                </Text>
                            </HStack>
                            <HStack spacing='1' alignItems="start">
                                <Circle size="40px" bg="green" color="white">
                                    <IconMap color="white"/>
                                </Circle>
                                <Text pt='2' fontSize='md'>
                                    {data?.data?.address?.street}
                                    {data?.data?.address?.street2 ? <br/>: ""}
                                    {data?.data?.address?.street2}
                                    <br/>
                                    {data?.data?.address?.city}, {data?.data?.address?.state} {data?.data?.address?.postalCode}
                                </Text>
                            
                            </HStack>
                        </VStack>
                        
                        
                    </HStack>
                </CardBody>
            </Card>
            <Card mb={4}>
                
                <CardBody>
                    <Heading size='md'>Contracts</Heading>
                    { templateAllList?.data?.data.map((template) => 
                        <HStack key={template.title} pt={1} cursor="pointer" onClick={() =>{onContractClick(template.id as string)}} color="gray">
                            <IconFileCheck/>
                            <Heading size='sm'>{template.title}</Heading>
                        </HStack>
                    )}

                    { templateCompanyList?.data?.data.map((template) => 
                        <HStack key={template.title} pt={1} cursor="pointer" onClick={() =>{onContractClick(template.id as string)}} color="green">
                            <IconFileCheck/>
                            <Heading size='sm'>{template.title}</Heading>
                        </HStack>
                    )}
                    
                </CardBody>
            </Card>
            <Card mb={4}>
                <CardBody>
                    <Heading size='md' mb={4}>Users</Heading> 
                    <TableContainer>
                        <Table size='sm' variant="simple" colorScheme="gray">
                            <Thead>
                            <Tr>
                                <Th>First Name</Th>
                                <Th>Last Name</Th>
                                <Th>Role</Th>
                            </Tr>
                            </Thead>
                            <Tbody>
                                {adminList.length === 0 && <Tr><Td colSpan={3}>No results.</Td></Tr> }
                                { adminList.map((user) => 
                                <Tr key={user.id} onClick={()=> navMethods.edit("administrator", user.id)} cursor="pointer">
                                    <Td>{user.firstName}</Td>
                                    <Td>{user.lastName}</Td>
                                    <Td>{user.role}</Td>
                                </Tr>
                                )}
                            </Tbody>
                        </Table>
                    </TableContainer>
                    <Button colorScheme='blue' mr={3} mt={3} onClick={ () => newUserClick()}>
                        Add New User 
                    </Button>
                </CardBody>
            </Card>
            <IntegrationsBox organization={data?.data as GetOrganizationQuery['organization']} />
        </Show>

        <Modal blockScrollOnMount={true} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add User</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        
                        <FormControl mt={4} isRequired={true}>
                            <FormLabel>First Name</FormLabel>
                            <Input 
                                id={firstNameInput}  
                                defaultValue={newUserFirst}
                                onChange={onNewUserModalChange}/>
                        </FormControl>
                        <FormControl mt={4} isRequired={true}>
                            <FormLabel>Last Name</FormLabel>
                            <Input 
                                id={lastNameInput} 
                                defaultValue={newUserLast}
                                onChange={onNewUserModalChange}/>
                        </FormControl>
                        <FormControl mt={4} isRequired={true}>
                            <FormLabel>Email</FormLabel>
                            <Input 
                                id={emailInput}  
                                defaultValue={newUserEmail}
                                onChange={onNewUserModalChange}/>
                        </FormControl>
                        <FormControl mt={4} isRequired={true}>
                            <FormLabel>Password</FormLabel>
                            <Input 
                                id={passwordInput} 
                                type="password"
                                defaultValue={newUserPassword}
                                onChange={onNewUserModalChange}/>
                        </FormControl>
                        <FormControl mt={4} isRequired={true}>
                            <FormLabel>Role</FormLabel>
                            <Select 
                                id="roleId" 
                                placeholder='Select Role'
                                onChange={onNewUserRoleModalChange}
                                >
                                    {options?.map((channel) => 
                                        <option key={channel.label} value={channel.value}>
                                            { channel.label }
                                    </option>
                        )}
                    
                            </Select>
                        </FormControl>
                        
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onSaveNewUserClick}>
                        Add User
                        </Button>
                        <Button onClick={ () => {
                            clearVarState();
                            onClose() }}
                        >
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
    </MainSection>
);
};