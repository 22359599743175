import { useEffect } from 'react';

const scriptRegistry: Map<string, Record<string, any>> = new Map();

export function registerScript(id: string, src: string, nameSpace?: string) {
    scriptRegistry.set(id, {
        src: src,
        loaded: false,
        nameSpace: nameSpace || 'all',
    });
}

export function registerScriptResolver(
    id: string,
    resolver: (context: any) => string[],
    nameSpace?: string
) {
    scriptRegistry.set(id, {
        resolver: resolver,
        loaded: false,
        nameSpace: nameSpace || 'all',
    });
}

function loadScript(url: string) {
    const promise = new Promise<void>((resolve, reject) => {
        console.log('loading: ' + url);

        var script = document.createElement('script');
        script.onload = function () {
            resolve();
        };
        script.src = url;

        document.head.appendChild(script);
    });

    return promise;
}

export function useLoadScripts(
    nameSpace: string,
    context?: any
) {
    useEffect(() => {
        for (let scriptState of scriptRegistry.values()) {
            if (scriptState.loaded) {
                continue;
            }

            if (scriptState.nameSpace === nameSpace || scriptState.nameSpace === 'all') {
                let scripts: string[] = [];

                if (scriptState.resolver) {
                    scripts = scriptState.resolver(context);
                } else if (scriptState.src) {
                    scripts.push(scriptState.src);
                }

                scripts.forEach((url) => {
                    loadScript(url);
                    scriptState.loaded = true;
                });
            }
        }
    }, [context]);
}
