import { useTable, flexRender } from "@pankod/refine-react-table";
import {
    List,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    HStack,
    Text,
    DateField,
    EditButton,
    DeleteButton,
    EmailField,
} from "@pankod/refine-chakra-ui";

import { ColumnFilter, ColumnSorter, MainSection } from "ui-core";
import {Pagination} from "ui-core"
import { useNavigation } from "@pankod/refine-core";


export const OrganizationList: React.FC = () => {
    
    const navMethods = useNavigation();
    
    const columns = [
       
        {
            id: 'name',
            header: 'Name',
            accessorKey: 'name',
            meta: {
                filterOperator: "contains",
            },
        },
        {
            id: 'email',
            header: 'Email',
            accessorKey: 'email',
            meta: {
                filterOperator: "contains",
            },
        },
    ];

    const {
        getHeaderGroups,
        getRowModel,
        setOptions,
        refineCore: {
            tableQueryResult: { data: tableData },
            setCurrent,
            pageCount,
            current,
        },
    } = useTable({
        columns,
    });

    

    setOptions((prev) => ({
        ...prev,
        meta: {
            ...prev.meta,
            
        },
    }));

    return (
        <MainSection>
        <List>
            <TableContainer whiteSpace="pre-line">
                <Table variant="simple" colorScheme="gray">
                    <Thead>
                        {getHeaderGroups().map((headerGroup) => (
                            <Tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <Th key={header.id}>
                                        {!header.isPlaceholder && (
                                            <HStack spacing="2">
                                                <Text>
                                                    {flexRender(
                                                        header.column.columnDef
                                                            .header,
                                                        header.getContext(),
                                                    )}
                                                </Text>
                                                <HStack spacing="2">
                                                    <ColumnSorter
                                                        column={header.column}
                                                    />
                                                    <ColumnFilter
                                                        column={header.column}
                                                    />
                                                </HStack>
                                            </HStack>
                                        )}
                                    </Th>
                                ))}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody>
                        {getRowModel().rows.map((row) => (
                            <Tr cursor="pointer" key={row.id}>
                                {row.getVisibleCells().map((cell) => (
                                    <Td key={cell.id} onClick={()=> navMethods.show("organization", tableData?.data[row.id as any].id as any)}>
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext(),
                                        )}
                                    </Td>
                                ))}
                                <Td>
                                    <EditButton 
                                        hideText
                                        size="small"
                                        recordItemId={tableData?.data[row.id as any].id as any}
                                    />
                                
                                    <DeleteButton
                                        hideText
                                        size="small"
                                        recordItemId={tableData?.data[row.id as any].id as any}
                                    />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
            <Pagination
                current={current}
                pageCount={pageCount}
                setCurrent={setCurrent}
            />
        </List>
        </MainSection>
       
    );
};