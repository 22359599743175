import { useEffect } from 'react';
import { Box } from '@chakra-ui/react';

import { AppEvents } from 'ui-core';

declare const ClearentSDK: any;

export function loadClearentScripts(config: any) {
    if (!config) {
        return [];
    }

    const settings = config.paymentMethodSettings?.find(
        (data: any) => data.handlerCode == 'clearent-credit-card'
    );

    let gatewayUrl = settings.testMode
        ? 'https://gateway-sb.clearent.net'
        : 'https://gateway.clearent.net';

    return [gatewayUrl + '/js-sdk/js/clearent-host.js'];
}

export const ClearentCreditCard = ({ parentState, config }: any) => {
    const settings = config?.paymentMethodSettings?.find(
        (data: any) => data.handlerCode == 'clearent-credit-card'
    );

    const handleSubmit = async () => {
        return ClearentSDK.getPaymentToken().then(
            (result: any) => {
                if (result.payload['mobile-jwt']) {
                    parentState.paymentMethodResult.current = {
                        code: 'credit-card',
                        transaction_token: result.payload['mobile-jwt']?.jwt,
                    };
                } else if (result.payload['ach-jwt']) {
                    parentState.paymentMethodResult.current = {
                        code: 'credit-card',
                        transaction_token: result.payload['ach-jwt']?.jwt,
                        type: 'ach',
                    };
                }
            },
            (error: any) => {
                console.log('ClearentTokenError');
                console.log(error);
                let errorMessage =
                    error.payload?.error && error.payload.error['error-message']
                        ? error.payload.error['error-message']
                        : 'Something went wrong.';
                throw new Error(errorMessage);
            }
        );
    };

    useEffect(() => {
        if (!settings) {
            return;
        }

        let gatewayUrl = settings.testMode
            ? 'https://gateway-sb.clearent.net'
            : 'https://gateway.clearent.net';

        if (typeof ClearentSDK !== 'undefined') {
            ClearentSDK.init({
                baseUrl: gatewayUrl,
                pk: settings.publishableKey,
                paymentFormId: 'clearent-payment-form',
                cardMasked: false,
                expDateMasked: false,
                cvcMasked: false,
                enableAch: parentState.enableAch,
                styles: "#payment-type-selector input[type='radio']{display: inline-block; vertical-align: middle; margin-right: 10px;} #payment-type-selector label{display: inline-block; vertical-align: middle;} @media screen and (max-width: 1140px){#payment-type-selector{display: block;} #payment-type-selector input[type='radio']{margin-right: 0; margin-bottom: 10px;}}",
            });
        }

        return () => {
            if (typeof ClearentSDK !== 'undefined') {
                ClearentSDK.reset();
            }
        };
    }, [settings]);

    useEffect(() => {
        const onEvent = async (stepId: string) => {
            if (stepId == 'payment') {
                return await handleSubmit();
            }
        };
        AppEvents.on('before_submit_step', onEvent);

        return () => {
            AppEvents.off('before_submit_step', onEvent);
        };
    }, [handleSubmit]);

    return (
        <Box>
            <Box id="clearent-payment-form"></Box>
        </Box>
    );
};
