import { useEffect } from 'react';
import { useNavigation } from '@pankod/refine-core';
import { Box, Skeleton, Stack } from '@chakra-ui/react';

import { CardSection, MainSection, useStartNewOrder } from 'ui-core';

import { ContractForm, useRtoOrderFormData } from 'ui-rto';

const OrderSkeleton = () => {
    return (
        <MainSection variant="form">
            <Box p={8}>
                <Stack>
                    <Skeleton height="48px" />
                    <Skeleton height="48px" />
                    <Skeleton height="48px" />
                </Stack>
            </Box>
        </MainSection>
    );
};

export const CreateOrder = () => {
    const startNewOrderMutation = useStartNewOrder({
        autoCreate: true,
        navigateOnSucess: true,
        onSuccess: (r, queryClient) => {
            queryClient.invalidateQueries({ queryKey: ['useRtoOrderFormData'] });
        },
    });
 /*   const sessionOrderQuery = useRtoOrderFormData();
    const navMethods = useNavigation();

    if (sessionOrderQuery.isLoading || startNewOrderMutation.isLoading) {
        return <OrderSkeleton />;
    }

    if (!sessionOrderQuery.data) {
        return null;
    }

    if (sessionOrderQuery.isError) {
        // Go to dashboard
        navMethods.replace('/');
        return null;
    }*/

    return (
        <MainSection variant="form">
            <CardSection>
                <Box px={4} py={3}>
                    <ContractForm />
                </Box>
            </CardSection>
        </MainSection>
    );
};
