import React from 'react';
import { Box, useMultiStyleConfig } from '@chakra-ui/react';

import { registerComponentStyle } from '../../../theme/theme-extension';

const themeKey = 'MainSection';

export type MainSectionProps = {
    variant?: 'list' | 'form';
    children?: React.ReactNode;
};

export const MainSection = (props: MainSectionProps) => {
    const themeStyles = useMultiStyleConfig(themeKey, { variant: props.variant });

    return (
        <Box __css={themeStyles.container}>
            <Box __css={themeStyles.body}>{props.children}</Box>
        </Box>
    );
};

registerComponentStyle(themeKey, {
    baseStyle: {
        container: {
            paddingX: [4, 8],
            paddingY: [4, 8],
        },
        body: {
            width: '100%',
            marginX: 'auto',
        },
    },
    variants: {
        list: {
            body: {
                maxWidth: '1200px',
            },
        },
        form: {
            body: {
                maxWidth: '680px',
            },
        },
    },
    defaultProps: {
        variant: 'list',
    },
});

export default MainSection;
