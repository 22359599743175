import { useNotification, useResource } from '@pankod/refine-core';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import type { GetRtoOrderFormDataQuery } from 'api-client/types';

import { getSessionOrderId, useApiSdk } from 'ui-core';

export function useRtoOrderFormData(orderId?: string) {
    const sdk = useApiSdk();
    const notification = useNotification();
    const resource = useResource({ resourceNameOrRouteName: 'orders' });
    const sessionOrderId = orderId || resource.id || getSessionOrderId();

    const queryResult = useQuery<GetRtoOrderFormDataQuery, any>({
        queryKey: ['useRtoOrderFormData', sessionOrderId],
        queryFn: async (context) => {
            const result = await sdk.GetRtoOrderFormData({ orderId: sessionOrderId || '' });
            return result;
        },
        onError: (error) => {
            console.log(error);

            notification.open &&
                notification.open({
                    type: 'error',
                    message: 'Failed to load order data.',
                });
        },
        staleTime: 5 * 60 * 1000, // no need to fetch on component mount
    });

    return queryResult;
}

export function useInvalidateRtoOrderData() {
    const queryClient = useQueryClient();

    return (orderId?: string | number) => {
        const queryKey = ['useRtoOrderFormData'];

        if (orderId) {
            queryKey.push(orderId.toString());
        }

        queryClient.invalidateQueries({ queryKey: queryKey });
    };
}

export default useRtoOrderFormData;
