import { AuthPage as CoreAuthPage, AuthProps } from '@pankod/refine-chakra-ui';

const AuthPage = (props: AuthProps) => {
    return <CoreAuthPage
        {...{
            registerLink: false,
            wrapperProps: {
                background: 'radial-gradient(50% 50% at 50% 50%, var(--chakra-colors-gray-200) 0%, var(--chakra-colors-gray-300) 100%)'
            },
            ...props
        }}
    />;
};

export default AuthPage;
