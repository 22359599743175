import {
    Create,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Select,
    Stack
} from "@pankod/refine-chakra-ui";
import { IResourceComponentsProps, useSelect } from "@pankod/refine-core";
import { useForm } from "@pankod/refine-react-hook-form";
import { MainSection, IUser, IChannelSelect } from "ui-core";

export const AdminCreate: React.FC<IResourceComponentsProps>  = () => {

    const {
        refineCore: { formLoading },
        saveButtonProps,
        register,
        formState: { errors },      
    } = useForm<IUser>({
        refineCoreProps: { 
            onMutationError: (error) => console.log('called: ' + error),
            errorNotification: (err: any, values, resource) => {
                let message = `Content Error`;
                let description = 'Unregistered Error : ' +err?.response?.errors[0]?.message;
                const dupeKeyDescription = 'User with that email address already exists'
                const dupeKeyMessage = 'Please enter a new email';
                

                if(err.response.errors[0].message.indexOf("duplicate key") >= 0){
                     description = dupeKeyDescription;
                     message = dupeKeyMessage;
                 }
                
                return {
                    key: `create-${resource}-notification`,
                    description,
                    message,
                    type: "error",
                }
            } 
        }
    });

    const {options} = useSelect<IChannelSelect>({
        resource: "role",
        optionLabel: "description",
        optionValue: "id",
        sort:[
            {
                field: "id",
                order: "asc",
            }
        ]
    });
    

return (
    <MainSection variant="form">
        <Create title="Create User" isLoading={formLoading} saveButtonProps={saveButtonProps}>
            <Stack spacing={4}>
                <FormControl isRequired>
                    <FormLabel>First Name</FormLabel>
                    <Input 
                        id="firstName"
                        placeholder="First Name" 
                        {...register("firstName", { required: "First Name is required" })}
                    />
                    <FormErrorMessage>
                        {`${errors.title?.message}`}
                    </FormErrorMessage>
                </FormControl>
                <FormControl isRequired>
                    <FormLabel>Last Name</FormLabel>
                    <Input 
                        id="lastName"
                        placeholder="Last Name" 
                        {...register("lastName", { required: "Last Name is required" })}
                    />
                    <FormErrorMessage>
                        {`${errors.title?.message}`}
                    </FormErrorMessage>
                </FormControl>
                <FormControl isRequired>
                    <FormLabel>Email</FormLabel>
                    <Input 
                        id="emailAddress"
                        placeholder="Email" 
                        type="email"
                        autoComplete="new-email"
                        {...register("emailAddress", { required: "Email is required" })}
                    />
                    <FormErrorMessage>
                        {`${errors.title?.message}`}
                    </FormErrorMessage>
                </FormControl>
                <FormControl isRequired>
                    <FormLabel>Password</FormLabel>
                    <Input 
                        id="password"
                        placeholder="Password" 
                        type="password"
                        autoComplete="new-password"
                        {...register("password", { required: "Password is required" })}
                    />
                    <FormErrorMessage>
                        {`${errors.title?.message}`}
                    </FormErrorMessage>
                </FormControl>

                <FormControl isRequired>
                    <FormLabel>Role</FormLabel>
                    <Select 
                        id="roleIds" 
                        placeholder='Select Role'
                        {...register("roleIds.0", { required: "Role is required" })}
                        >
                            {options?.map((channel) => 
                                <option key={channel.label} value={channel.value}>
                                    { channel.label }
                                </option>
                            )}
                        
                    </Select>
                </FormControl>
            </Stack>
        </Create>
    </MainSection>
);
};