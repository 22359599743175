import { useTable, flexRender } from '@pankod/refine-react-table';
import {
    List,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    HStack,
    Select,
    Text,
    NumberField,
} from '@pankod/refine-chakra-ui';
import { useNavigation } from '@pankod/refine-core';

import { ColumnFilter, ColumnSorter, MainSection, Pagination, useAppModeData } from 'ui-core';

export const PaymentList: React.FC = () => {
    const navMethods = useNavigation();
    const appModeData = useAppModeData();

    const columns = [
        {
            id: 'orderType',
            header: 'Source',
            accessorKey: 'order.customFields.orderType',
            cell: ({ getValue }: any) => {
                return getValue() == 'external' ? 'Payment Form' : 'Contract';
            },
            enableSorting: false,
            enableColumnFilter: false,
        },
        {
            id: 'customerName',
            header: 'Customer',
            accessorKey: 'order.billingAddress',
            cell: ({ getValue }: any) => {
                return getValue()?.fullName || '';
            },
            meta: {
                filterOperator: 'contains',
            },
            enableSorting: false,
        },
        {
            id: 'amount',
            header: 'Amount',
            accessorKey: 'amount',
            cell: ({ getValue }: any) => {
                return (
                    <NumberField
                        options={{ style: 'currency', currency: 'USD' }}
                        value={getValue() / 100}
                    />
                );
            },
        },
        {
            id: 'createdAt',
            header: 'Date',
            accessorKey: 'createdAt',
            cell: ({ getValue }: any) => {
                const date = new Date(getValue());
                return new Intl.DateTimeFormat(undefined, {
                    dateStyle: 'short',
                    timeStyle: 'short',
                }).format(date);
            },
        },
        {
            id: 'state',
            header: 'Status',
            accessorKey: 'state',
            cell: (props: any) => {
                if (props.row.original?.refunds?.length) {
                    return 'Refunded';
                }
                return props.getValue() == 'Settled' ? 'Captured' : props.getValue();
            },
            meta: {
                filterElement: function render(props: any) {
                    return (
                        <Select {...props}>
                            <option value="">Any</option>
                            <option value="Settled">Captured</option>
                            <option value="Cancelled">Cancelled</option>
                        </Select>
                    );
                },
                filterOperator: 'eq',
            },
        },
    ];

    if (appModeData.mode == 'superAdmin') {
        columns.push({
            id: 'organizationName',
            header: 'Company',
            accessorKey: 'order.customFields.organization',
            cell: ({ getValue }: any) => {
                return getValue()?.name || '';
            },
            meta: {
                filterOperator: 'contains',
            },
            enableSorting: false,
        });
    }

    const {
        getHeaderGroups,
        getRowModel,
        setOptions,
        refineCore: {
            tableQueryResult: { data: tableData },
            setCurrent,
            pageCount,
            current,
        },
    } = useTable({
        columns,
        refineCoreProps: {
            resource: 'payments',
            initialSorter: [
                {
                    field: 'id',
                    order: 'desc',
                },
            ],
            metaData: {
                operation: 'GET_PAYMENT_LIST',
            },
        },
    });

    setOptions((prev) => ({
        ...prev,
        meta: {
            ...prev.meta,
        },
    }));

    const doRowNav = (payment: any) => {
        if (payment?.order?.id) {
            navMethods.show('payments', payment?.order?.id);
        }
    }

    return (
        <MainSection>
            <List>
                <TableContainer whiteSpace="pre-line">
                    <Table variant="simple" colorScheme="gray">
                        <Thead>
                            {getHeaderGroups().map((headerGroup) => (
                                <Tr key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => (
                                        <Th key={header.id}>
                                            {!header.isPlaceholder && (
                                                <HStack spacing="2">
                                                    <Text>
                                                        {flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext()
                                                        )}
                                                    </Text>
                                                    <HStack spacing="2">
                                                        <ColumnSorter column={header.column} />
                                                        <ColumnFilter column={header.column} />
                                                    </HStack>
                                                </HStack>
                                            )}
                                        </Th>
                                    ))}
                                </Tr>
                            ))}
                        </Thead>
                        <Tbody>
                            {getRowModel().rows.map((row) => (
                                <Tr cursor="pointer" key={row.id}>
                                    {row.getVisibleCells().map((cell) => (
                                        <Td key={cell.id} onClick={() => doRowNav(row.original)}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </Td>
                                    ))}
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
                <Pagination current={current} pageCount={pageCount} setCurrent={setCurrent} />
            </List>
        </MainSection>
    );
};
