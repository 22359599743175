import { Box, Button, Table, Tbody, Tr, Td } from '@chakra-ui/react';
import { Show } from '@pankod/refine-chakra-ui';
import { useOne, useRouterContext, useInvalidate, useNotification } from '@pankod/refine-core';

import { FormSkeleton, MainSection, useApiSdk } from 'ui-core';
import { formatPrice, usePaymentReceipt } from 'ui-rto';
import type { GetOrderQuery, RefundOrderMutation, RefundOrderInput } from 'api-client/types';
import { useMutation } from '@tanstack/react-query';
import { useSearchParams } from '@pankod/refine-react-router-v6';

type OrderDetail = NonNullable<GetOrderQuery['order']>;

export const ShowPayment = () => {
    const routerContext = useRouterContext();
    const params = routerContext.useParams<{ id?: string }>();
    const [searchParams] = useSearchParams(); 
    const queryResult = useOne<OrderDetail>({ resource: 'orders', id: params.id || '' });
    const order = queryResult.data?.data;
    const paymentId = searchParams.get('payment_id');
    let payment = order?.payments ? order.payments[0] : null;

    if (paymentId && order?.payments) {
        payment = order.payments.find((p) => p.id == paymentId) || payment;
    }

    const HeaderButtons = () => {
        const sdk = useApiSdk();
        const invalidate = useInvalidate();
        const notification = useNotification();
        const payment = order?.payments?.find(
            (p: any) => p.state == 'Settled' && p.refunds.length == 0
        );

        const paymentReceipt = usePaymentReceipt({
            order,
            payment,
        });

        const refundPaymentMutation = useMutation<
            RefundOrderMutation['refundOrder'],
            Error,
            RefundOrderInput
        >({
            mutationFn: async (vars) => {
                const response = await sdk.RefundOrder({
                    input: vars,
                });

                return response?.refundOrder;
            },
            onSuccess: (response) => {
                invalidate({
                    resource: 'orders',
                    invalidates: ['detail'],
                    id: order?.id,
                });

                if ('message' in response) {
                    notification.open &&
                        notification.open({
                            type: 'error',
                            message: 'Error',
                            description: response.message,
                        });
                } else if ('id' in response) {
                    notification.open &&
                        notification.open({
                            type: 'success',
                            message: 'Success',
                            description: 'Payment refunded.',
                        });
                }
            },
        });

        const handleClickRefund = () => {
            if (!payment) {
                return;
            }

            refundPaymentMutation.mutate({
                paymentId: payment.id,
                lines: order
                    ? order.lines.map((line: any) => ({
                          orderLineId: line.id,
                          quantity: line.quantity,
                      }))
                    : [],
                adjustment: payment.amount - (order?.total || 0),
                shipping: 0,
            });
        };

        const handleClickPrint = () => {
            if (!payment || !order) {
                return;
            }

            paymentReceipt.print();
        };

        if (!payment) {
            return null;
        }

        return (
            <>
                <Button
                    variant="outline"
                    isLoading={paymentReceipt.queryResult.isLoading}
                    onClick={handleClickPrint}
                >
                    Print
                </Button>
                <Button
                    variant="outline"
                    onClick={handleClickRefund}
                    isLoading={refundPaymentMutation.isLoading}
                >
                    Refund
                </Button>
            </>
        );
    };

    if (queryResult.isLoading) {
        return <FormSkeleton />;
    }

    if (queryResult.isError || !order || !payment) {
        return <Box p={8}>Something went wrong.</Box>;
    }

    let paymentType = 'Offline';

    if (payment.method == 'credit-card') {
        paymentType = 'Credit Card';
        if (payment.metadata && payment.metadata.type == 'ach') {
            paymentType = 'ACH';
        }
    }

    const paymentDate = new Date(payment.createdAt);
    const paymentFormattedDate = new Intl.DateTimeFormat(undefined, {
        dateStyle: 'short',
        timeStyle: 'short',
    }).format(paymentDate);

    return (
        <MainSection variant="form">
            <Show title="Payment" headerButtons={HeaderButtons}>
                <Table colorScheme="gray" borderTop="1px" borderColor="gray.100" mt={8}>
                    <Tbody>
                        <Tr>
                            <Td fontWeight="semibold">Type</Td>
                            <Td>{paymentType}</Td>
                        </Tr>
                        <Tr>
                            <Td fontWeight="semibold">Amount</Td>
                            <Td>{formatPrice(payment.amount / 100)}</Td>
                        </Tr>
                        <Tr>
                            <Td fontWeight="semibold">Transaction ID</Td>
                            <Td>{payment.transactionId}</Td>
                        </Tr>
                        <Tr>
                            <Td fontWeight="semibold">Date</Td>
                            <Td>{paymentFormattedDate}</Td>
                        </Tr>
                        <Tr>
                            <Td fontWeight="semibold">Source</Td>
                            <Td>{order.customFields?.orderType == 'external' ? 'Payment Form' : 'Contract'}</Td>
                        </Tr>
                    </Tbody>
                </Table>
            </Show>
        </MainSection>
    );
};
