import { ADMINISTRATOR_FRAGMENT } from './administrator';

export const ORGANIZATION_FRAGMENT = /* GraphQL */ `
    fragment Organization on Organization {
        id
        createdAt
        updatedAt
        channel {
            id
            code
            token
        }
        name
        email
        phone
        address {
            street
            street2
            city
            state
            postalCode
        }
        settings
        
    }
    
`;

export const UPDATE_ORGANIZATION_FRAGMENT = /* GraphQL */ `
    fragment UpdateOrganizationResult on Organization {
        id
        createdAt
        updatedAt
        name
        email
        phone
        address {
            street
            street2
            city
            state
            postalCode
        }
        settings
        
    }
    
`;

export const PARTIAL_ORGANIZATION_FRAGMENT = /* GraphQL */ `
    fragment PartialOrganization on Organization {
        id
        createdAt
        updatedAt
        channel {
            id
            code
        }
        name
        email
        phone
        address {
            street
            street2
            city
            state
            postalCode
        }
    }
`;

export const GET_ORGANIZATION_LIST = /* GraphQL */ `
    query GetOrganizations($options: OrganizationListOptions) {
        organizations(options: $options) {
            items {
                ...PartialOrganization
            }
            totalItems
        }
    }
    ${PARTIAL_ORGANIZATION_FRAGMENT}
`;

export const GET_ACTIVE_ORGANIZATION = /* GraphQL */ `
    query GetActiveOrganization {
        activeOrganization {
            ...Organization
        }
    }
    ${ORGANIZATION_FRAGMENT}
`;

export const GET_ORGANIZATION = /* GraphQL */ `
    query GetOrganization($id: ID!) {
        organization(id: $id) {
            ...Organization
        }
    }
    ${ORGANIZATION_FRAGMENT}
`;

export const CREATE_ORGANIZATION = /* GraphQL */ `
    mutation CreateOrganization($input: CreateOrganizationInput!) {
        createOrganization(input: $input) {
            ...Organization
        }
    }
    ${ORGANIZATION_FRAGMENT}
`;

export const UPDATE_ORGANIZATION = /* GraphQL */ `
    mutation UpdateOrganization($input: UpdateOrganizationInput!) {
        updateOrganization(input: $input) {
            ...UpdateOrganizationResult
        }
    }
    ${UPDATE_ORGANIZATION_FRAGMENT}
`;

export const DELETE_ORGANIZATION = /* GraphQL */ `
    mutation DeleteOrganization($id: ID!) {
        deleteOrganization(id: $id) {
            result
            message
        }
    }
`;
