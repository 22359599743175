import {
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    Flex,
    Heading,
    Table,
    Tbody,
    Thead,
    Td,
    Th,
    Tr,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { useInvalidate, useNavigation, useNotification } from '@pankod/refine-core';

import { useAppModeData, useApiSdk } from 'ui-core';
import type { RefundOrderMutation, RefundOrderInput } from 'api-client/types';

export type PaymentsBoxProps = {
    order: any;
};

export const PaymentsBox = (props: PaymentsBoxProps) => {
    const appModeData = useAppModeData();
    const sdk = useApiSdk();
    const invalidate = useInvalidate();
    const notification = useNotification();
    const navMethods = useNavigation();
    const payment = props.order?.payments?.find(
        (p: any) => p.state == 'Settled' && p.refunds.length == 0
    );

    const refundPaymentMutation = useMutation<
        RefundOrderMutation['refundOrder'],
        Error,
        RefundOrderInput
    >({
        mutationFn: async (vars) => {
            const response = await sdk.RefundOrder({
                input: vars,
            });

            return response?.refundOrder;
        },
        onSuccess: (response) => {
            invalidate({
                resource: 'orders',
                invalidates: ['detail'],
                id: props.order.id,
            });

            if ('message' in response) {
                notification.open &&
                    notification.open({
                        type: 'error',
                        message: 'Error',
                        description: response.message,
                    });
            } else if ('id' in response) {
                notification.open &&
                    notification.open({
                        type: 'success',
                        message: 'Success',
                        description: 'Payment refunded.',
                    });
            }
        },
    });

    const handleClickRefund = () => {
        if (!payment) {
            return;
        }

        refundPaymentMutation.mutate({
            paymentId: payment.id,
            lines: props.order.lines.map((line: any) => ({
                orderLineId: line.id,
                quantity: line.quantity,
            })),
            adjustment: payment.amount - props.order.total,
            shipping: 0,
        });
    };

    const handleClickRow = () => {
        let paymentUrl = navMethods.showUrl('payments', props.order?.id);
        paymentUrl += '?payment_id=' + encodeURIComponent(payment?.id);
        navMethods.push(paymentUrl);
    }

    const hoverStyle = {
        bg: 'gray.100',
    };

    return (
        <Card>
            <CardHeader>
                <Flex w="100%" justifyContent="space-between">
                    <Heading size="md">Payment</Heading>
                    {appModeData.mode == 'superAdmin' && payment && (
                        <Button
                            variant="outline"
                            size="sm"
                            onClick={handleClickRefund}
                            isLoading={refundPaymentMutation.isLoading}
                        >
                            Refund
                        </Button>
                    )}
                </Flex>
            </CardHeader>
            <CardBody>
                <Table colorScheme="gray">
                    <Thead>
                        <Tr>
                            <Th pl={0}>Type</Th>
                            <Th>ID</Th>
                            <Th>Status</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {props.order?.payments.map((payment: any) => (
                            <Tr key={payment.id} cursor="pointer" onClick={handleClickRow} data-group>
                                <Td pl={0} _groupHover={hoverStyle}>
                                    {payment.method == 'credit-card'
                                        ? payment.metadata.type == 'ach'
                                            ? 'ACH'
                                            : 'Credit Card'
                                        : 'Offline'}
                                </Td>
                                <Td _groupHover={hoverStyle}>{payment.transactionId}</Td>
                                <Td _groupHover={hoverStyle}>
                                    {payment.state == 'Cancelled'
                                        ? 'Cancelled'
                                        : payment.refunds.length > 0
                                        ? 'Refunded'
                                        : 'Captured'}
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </CardBody>
        </Card>
    );
};
