import { Box, Skeleton, Stack } from "@pankod/refine-chakra-ui";
import { IResourceComponentsProps, useNavigation } from "@pankod/refine-core";
import { useForm } from "@pankod/refine-react-hook-form";
import { CardSection, MainSection } from "ui-core";
import { useRtoOrderFormData, ContractForm } from "ui-rto";

const OrderSkeleton = () => {
    return (
        <MainSection variant="form">
            <Box p={8}>
                <Stack>
                    <Skeleton height="48px" />
                    <Skeleton height="48px" />
                    <Skeleton height="48px" />
                </Stack>
            </Box>
        </MainSection>
    );
};

export const EditOrder: React.FC<IResourceComponentsProps> = () => {

    const {
        refineCore: { formLoading, queryResult },
    } = useForm();

    const postData = queryResult?.data?.data;
    console.log(postData);

    const sessionOrderQuery = useRtoOrderFormData();
    const navMethods = useNavigation();

    if (sessionOrderQuery.isLoading || formLoading) {
        return <OrderSkeleton />;
    }

    if (!sessionOrderQuery.data) {
        return null;
    }

    if (sessionOrderQuery.isError) {
        // Go to dashboard
        navMethods.replace('/');
        return null;
    }

    return (
        <MainSection variant="form">
            <CardSection>
                <Box px={4} py={3}>
                    <ContractForm />
                </Box>
            </CardSection>
        </MainSection>
    );
};