import { useEffect } from 'react';
import { useNavigation } from '@pankod/refine-core';
import { QueryClient, useQueryClient, useMutation } from '@tanstack/react-query';
import type { Order } from 'api-client/types';

import { useApiSdk } from './use-api-sdk';
import { setSessionOrderId, getSessionOrderId } from './use-session-order';

type StartNewOrderOptions = {
    autoCreate?: boolean;
    navigateOnSucess?: boolean;
    onSuccess?: (order: any, queryClient: QueryClient) => void;
    onError?: (error: any) => void;
};

export function useStartNewOrder(options?: StartNewOrderOptions) {
    const queryClient = useQueryClient();
    const navMethods = useNavigation();
    const sdk = useApiSdk();
    const sessionOrderId = getSessionOrderId();

    // Uses useMutation instead of useCreate
    // in order to have to have complete control of the success and error handlers

    const mutationResult = useMutation({
        mutationFn: async () => {
            const result = await sdk.CreateDraftOrder();

            return result.createDraftOrder;
        },
        onSuccess: (response) => {
            setSessionOrderId(response.id);

            options?.onSuccess && options.onSuccess(response, queryClient);

            if (options?.navigateOnSucess) {
                navMethods.edit('orders', response.id as string);
            }
        },
        onError: (error) => {
            options?.onError && options.onError(error);
        },
    });

    useEffect(() => {
        if (
            !sessionOrderId &&
            options?.autoCreate &&
            !mutationResult.data &&
            !mutationResult.isLoading
        ) {
            mutationResult.mutate();
        }
    }, [sessionOrderId]);

    return mutationResult;
}

export default useStartNewOrder;
