import { useMemo } from 'react';
import { useMenu as coreUseMenu } from '@pankod/refine-core';

import { useAppModeData } from '../components/context/app-mode-provider';

export function useMenu() {
    const menuObject = coreUseMenu();
    const appModeData = useAppModeData();

    // Filter menu based on the app mode
    const visiblePages = appModeData.pages[appModeData.mode];

    const newMenuObject = useMemo(() => ({
        ...menuObject,
        menuItems: menuObject.menuItems.filter((item) =>
            visiblePages ? visiblePages.indexOf(item.name) >= 0 : false
        ),
    }), [menuObject, visiblePages]);

    return newMenuObject;
}
