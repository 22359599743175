import { useOne } from '@pankod/refine-core';
import type { Order } from 'api-client/types';

import { useStartNewOrder } from './use-start-new-order';

const ID_STORAGE_KEY = 'session_order_id';

export function getSessionOrderId() {
    return localStorage.getItem(ID_STORAGE_KEY);
}

export function setSessionOrderId(id: string) {
    localStorage.setItem(ID_STORAGE_KEY, id);
}

type UseSessionOrderOptions = {
    autoCreate: boolean;
};

export function useSessionOrder(
    options?: UseSessionOrderOptions
) {
    const sessionOrderId = getSessionOrderId();
    const startNewOrder = useStartNewOrder();

    const query = useOne<Order>({
        resource: 'orders',
        id: sessionOrderId || 0,
        queryOptions: {
            onSuccess: (data) => {
                if (!data.data && options && options.autoCreate) {
                    // Sets the session order on success
                    startNewOrder.mutate();
                }
            },
            retry: false,
            staleTime: 5 * 60 * 1000, // no need to fetch on component mount
        },
        errorNotification: {
            message: 'Failed to load session order.',
            type: 'error',
        },
    });

    return query;
}
