import { ERROR_RESULT_FRAGMENT } from './shared';

export const CURRENT_USER_FRAGMENT = /* GraphQL */ `
    fragment CurrentUser on CurrentUser {
        id
        identifier
        channels {
            id
            code
            token
            permissions
        }
    }
`;

export const ATTEMPT_LOGIN = /* GraphQL */ `
    mutation AttemptLogin($username: String!, $password: String!, $rememberMe: Boolean!) {
        login(username: $username, password: $password, rememberMe: $rememberMe) {
            ...CurrentUser
            ...ErrorResult
        }
    }
    ${CURRENT_USER_FRAGMENT}
    ${ERROR_RESULT_FRAGMENT}
`;

export const LOG_OUT = /* GraphQL */ `
    mutation LogOut {
        logout {
            success
        }
    }
`;

export const GET_CURRENT_USER = /* GraphQL */ `
    query GetCurrentUser {
        me {
            ...CurrentUser
        }
    }
    ${CURRENT_USER_FRAGMENT}
`;

export const ADMIN_RESET_PASSWORD = /* GraphQL */ `
    mutation AdminResetPassword($token: String!, $password: String!) {
        resetPassword(token: $token, password: $password) {
            ...CurrentUser
            ...ErrorResult
        }
    }
    ${CURRENT_USER_FRAGMENT}
    ${ERROR_RESULT_FRAGMENT}
`;

export const ADMIN_REQUEST_RESET_PASSWORD = /* GraphQL */ `
    mutation AdminRequestResetPassword($emailAddress: String!) {
        requestPasswordReset(emailAddress: $emailAddress) {
            ... on Success {
                success
            } 
            ...ErrorResult
        }
    }
    ${ERROR_RESULT_FRAGMENT}
`;
