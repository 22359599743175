import { GraphQLClient } from 'graphql-request';
import snakeCase from 'lodash/snakeCase';
import pluralize from 'pluralize';

export * from './generated/graphql-sdk';
// export * from './generated/types';
import * as GqlDocuments from './gql-documents';
export { GqlDocuments };

export type TokenGetter = () => string | null | undefined;

type MaybeFunctionHeaders = RequestInit['headers'] | (() => RequestInit['headers'] | undefined);

export function getClient(url: string, headers?: MaybeFunctionHeaders): GraphQLClient {
    return new GraphQLClient(url, { headers });
}

export function getHeaders(
    getAuthToken: TokenGetter,
    getChannelToken?: TokenGetter
): MaybeFunctionHeaders {
    return (): RequestInit['headers'] | undefined => {
        const authToken = getAuthToken();
        const channelToken = getChannelToken ? getChannelToken() : null;
        const headers: RequestInit['headers'] = {};

        if (channelToken) {
            headers['vendure-token'] = channelToken;
        }

        if (authToken) {
            headers['Authorization'] = `Bearer ${authToken}`;
        }

        return headers;
    };
}

export type ResourceAction = 'create' | 'get' | 'update' | 'delete';

function isKeyOfGqlDocuments(key: string): key is keyof typeof GqlDocuments {
    return key in GqlDocuments;
}

/**
 * Returns the GraphQL document string defined in gql-documents module
 * from a resource name + action or an operation name.
 *
 * Examples:
 *   ('administrators', 'get') returns the document for the 'administrators' query operation.
 *   ('administrators', 'create') returns the document for the 'createAdministrator' mutation operation.
 *   ('cancelOrder') returns the document for the 'cancelOrder' operation.
 *
 * @param operationOrResource Either the name of a GraphQL operation or the name of a resource.
 * @param action CRUD resource action.
 */
export function getGqlDocument(
    operationOrResource: string,
    action: ResourceAction = 'get', 
    isOne: boolean = false
): string {
    let documentKey = snakeCase(operationOrResource).toUpperCase();

    //check to see if key already exists
    if (isKeyOfGqlDocuments(documentKey)) {
        return GqlDocuments[documentKey];
    }
    
    //list requests are simply the get action, so if a path is included
    // as an argument then it is more than a list request
    if(operationOrResource.includes('/')) {  
        const argumentArray = operationOrResource.split("/");
        operationOrResource = argumentArray[0];
        const argumentAction = argumentArray[1];
        if(argumentArray.length > 2) {
            const resrouceId = argumentArray[2];
        }
        documentKey = snakeCase(action + '_' + operationOrResource ).toUpperCase();
    } else if(action === "get" && isOne === false){
        documentKey = snakeCase(action + '_' + operationOrResource + '_list').toUpperCase();
    } else {
        documentKey = snakeCase(action + '_' + operationOrResource ).toUpperCase();
    }
    if(action === "delete" && operationOrResource === "orders") {
        documentKey = "DELETE_DRAFT_ORDER";
    }


 /*   const singularName = pluralize.singular(operationOrResource);
    const isPlural = singularName != operationOrResource;
    if(action === 'get'){
        documentKey = isPlural ? singularName + '_list' : singularName;
    } else {
        documentKey = singularName;
    }
   documentKey = snakeCase(action + '_' + documentKey).toUpperCase();
*/
//console.log(documentKey);
    if (!isKeyOfGqlDocuments(documentKey)) {
       throw new Error(`GraphQL document not defined for '${operationOrResource}' operation.`); 
    }

    return GqlDocuments[documentKey];
}
