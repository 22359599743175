import { useAppModeData } from 'ui-core';

import SellerDashboard from './dashboard/seller-dashboard';
import AdminDashboard from './dashboard/admin-dashboard';

export const DashboardPage = () => {
    const appModeData = useAppModeData();

    switch (appModeData.mode) {
        case 'user':
            return <SellerDashboard />;
        case 'superAdmin':
            return <AdminDashboard />;
        default:
            return  <SellerDashboard />;
    }
};

export default DashboardPage;
