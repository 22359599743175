import {
    RadioGroup,
    RadioGroupProps,
} from '@chakra-ui/react';
import { useController, UseControllerProps } from 'react-hook-form';

export const RhfRadioGroup = (props: RadioGroupProps & UseControllerProps<any, any>) => {
    const { name, control, defaultValue, rules, shouldUnregister, ...inputProps } = props;

    const controller = useController({
        name,
        control,
        defaultValue,
        rules,
        shouldUnregister,
    });

    return (
        <RadioGroup
            {...inputProps}
            name={controller.field.name}
            value={controller.field.value}
            onChange={(value) => {
                controller.field.onChange(value);
            }}
            onBlur={controller.field.onBlur}
            ref={controller.field.ref}
        >
            {props.children}
        </RadioGroup>
    );
};
