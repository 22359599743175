import {
    Box,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    HStack,
    VStack,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

import { ActionBar, RhfPhoneInput, useApiSdk } from 'ui-core';
import {
    useRtoOrderFormData,
    useInvalidateRtoOrderData,
} from '../../../hooks/use-rto-order-form-data';

export const StepReferences = () => {
    const pageQuery = useRtoOrderFormData();
    const invalidate = useInvalidateRtoOrderData();
    const sdk = useApiSdk();
    const order = pageQuery.data?.order;
    const orderFields = order?.rtoContract?.fields;

    const initialValues = {
        reference1_name: orderFields?.reference1_name || '',
        reference1_relationship: orderFields?.reference1_relationship || '',
        reference1_phone: orderFields?.reference1_phone || '',
        reference2_name: orderFields?.reference2_name || '',
        reference2_relationship: orderFields?.reference2_relationship || '',
        reference2_phone: orderFields?.reference2_phone || '',
    };

    const form = useForm({ defaultValues: initialValues });
    const register = form.register;
    const errors = form.formState.errors;

    const onSubmit = async (formData: any) => {
        const result = await sdk.SetRtoAppFields({
            input: {
                orderId: order?.id || '',
                fields: {
                    ...formData,
                },
            },
        });

        if (result.setRtoAppFields?.id) {
            invalidate(result.setRtoAppFields?.id);
        }
    };

    const handleClickContinue = async () => {
        return form.handleSubmit(onSubmit, () => {
            throw new Error('Invalid form.');
        })();
    };

    return (
        <Box>
            <VStack width="100%" alignItems="start" spacing={10} color="gray.700">
                <HStack spacing={8} w="100%">
                    <Box flex="1">
                        <Box mb={4} fontWeight="semibold">
                            Reference 1
                        </Box>
                        <VStack alignItems="start" spacing={4}>
                            <FormControl isInvalid={!!errors.reference1_name}>
                                <FormLabel>Name</FormLabel>
                                <Input
                                    {...register('reference1_name', { required: 'Required.' })}
                                />
                                <FormErrorMessage>
                                    {errors.reference1_name?.message}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={!!errors.reference1_relationship}>
                                <FormLabel>Relationship</FormLabel>
                                <Input
                                    {...register('reference1_relationship', {
                                        required: 'Required.',
                                    })}
                                />
                                <FormErrorMessage>
                                    {errors.reference1_relationship?.message}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={!!errors.reference1_phone}>
                                <FormLabel>Phone</FormLabel>
                                <RhfPhoneInput
                                    name="reference1_phone"
                                    control={form.control}
                                    rules={{ required: 'Required.' }}
                                />
                                <FormErrorMessage>
                                    {errors.reference1_phone?.message}
                                </FormErrorMessage>
                            </FormControl>
                        </VStack>
                    </Box>
                    <Box flex="1">
                        <Box mb={4} fontWeight="semibold">
                            Reference 2
                        </Box>
                        <VStack alignItems="start" flex="1" spacing={4}>
                            <FormControl isInvalid={!!errors.reference2_name}>
                                <FormLabel>Name</FormLabel>
                                <Input
                                    {...register('reference2_name', { required: 'Required.' })}
                                />
                                <FormErrorMessage>
                                    {errors.reference2_name?.message}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={!!errors.reference2_relationship}>
                                <FormLabel>Relationship</FormLabel>
                                <Input
                                    {...register('reference2_relationship', {
                                        required: 'Required.',
                                    })}
                                />
                                <FormErrorMessage>
                                    {errors.reference2_relationship?.message}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={!!errors.reference2_phone}>
                                <FormLabel>Phone</FormLabel>
                                <RhfPhoneInput
                                    name="reference2_phone"
                                    control={form.control}
                                    rules={{ required: 'Required.' }}
                                />
                                <FormErrorMessage>
                                    {errors.reference2_phone?.message}
                                </FormErrorMessage>
                            </FormControl>
                        </VStack>
                    </Box>
                </HStack>
                <ActionBar onSubmit={handleClickContinue} />
            </VStack>
        </Box>
    );
};

export default StepReferences;
