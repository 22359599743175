import React, { createContext, useCallback, useContext, useReducer } from 'react';

type ActionType<T> =
    | {
          type?: string;
          payload?: any;
      }
    | ((data: T) => T);

type DispatchActions<A> = {
    [Prop in keyof A]: (payload?: any) => void;
};

export type DataStoreProviderProps<T = any> = {
    initialData: T;
    children?: React.ReactNode;
};

export type DataStoreUpdater<T> = (data: T) => T;

export function createContextDataStore<T = Record<string, any>>(
    reducers: Record<string, (data: T, payload: any) => T>
) {
    const context = createContext<[T, React.Dispatch<ActionType<T>>] | []>([]);

    function rootReducer(previousData: T, action: ActionType<T>): T {
        if (typeof action === 'function') {
            return action(previousData);
        }

        return previousData;
    }

    const Provider = (props: DataStoreProviderProps) => {
        const dataStore = useReducer(rootReducer, props.initialData);

        return <context.Provider value={dataStore}>{props.children}</context.Provider>;
    };

    return {
        Provider,
        useData: () => {
            const [data] = useContext(context);

            if (!data) {
                throw new Error('ContextDataStore: Called useData before context was initialized.');
            }

            return data;
        },
        useDispatch: (): DispatchActions<typeof reducers> => {
            const [data, dispatch] = useContext(context);

            if (!dispatch) {
                throw new Error('ContextDataStore: Called useDispatch before context was initialized.');
            }

            let dispatchActions = Object.fromEntries(
                Object.entries(reducers).map(([k, reducerFn]) => {
                    return [k, (payload?: any) => dispatch((data) => reducerFn(data, payload))];
                })
            );

            return dispatchActions;
        },
    };
}
