import React, { useEffect, useMemo } from 'react';
import type { Administrator } from 'api-client/types';
import { getChannelToken, getSessionAdmin } from '../../auth-provider';
import { useCurrentAdmin } from '../../hooks/use-current-admin';
import { createContextDataStore, DataStoreProviderProps } from '../../context-data-store';

export type AppMode = 'superAdmin' | 'user' | 'none' | 'sellerAdmin';
export type AppModePages = Partial<Record<AppMode, string[]>>;

export type AppModeContextType = {
    mode: AppMode;
    pages: AppModePages;
};

let globalInitialState: AppModeContextType = {
    mode: 'none',
    pages: { superAdmin: [], user: [], sellerAdmin: [] },
};

export function setDefaultAppModeContext(contextData: Partial<AppModeContextType>) {
    globalInitialState = { ...globalInitialState, ...contextData };
}

const appModeDataStore = createContextDataStore<AppModeContextType>({
    setMode: (data, mode: AppMode) => {
        return {
            ...data,
            mode,
        };
    },
    setPages: (data, pages: AppModePages) => {
        return {
            ...data,
            pages: {
                ...data.pages,
                ...pages,
            },
        };
    },
});

export const useAppModeData = appModeDataStore.useData;
export const useAppModeDispatch = appModeDataStore.useDispatch;

export type AppModeProviderProps = Partial<DataStoreProviderProps<AppModeContextType>>;

function getModeForUser(admin: Administrator | null): AppMode {
    if (!admin) {
        return 'none';
    }

    const channelToken = getChannelToken();

    if (!channelToken) {
        return 'none';
    }

    let channelCode = '';
    let isAdmin = false;

    if ('user' in admin) {
        admin.user.roles.forEach((role) => {
            const channel = role.channels.find((c) => c.token == channelToken);
            if (channel) {
                channelCode = channel.code;
            }
            
            if (role.code.includes('admin')) {
                isAdmin = true;
            }
        });
    }

    if(channelCode === '__default_channel__' && isAdmin) {
        return 'superAdmin';
    } else if(isAdmin) {
        return 'sellerAdmin';
    } 

    return 'user';
}

const AppModeController: React.FC = (props) => {
    const appModeData = useAppModeData();
    const dispatch = useAppModeDispatch();
    const adminQuery = useCurrentAdmin();
    const admin = adminQuery.data?.data;

    // Update mode if user changes
    useEffect(() => {
        if (admin) {
            const newMode = getModeForUser(admin);
            if (newMode !== appModeData.mode) {
                dispatch.setMode(newMode);
            }
        }
    }, [admin]);

    return <>{props.children}</>;
};

export const AppModeProvider = (props: AppModeProviderProps) => {
    const initialData = useMemo(() => {
        return {
            ...globalInitialState,
            ...props.initialData,
            mode: getModeForUser(getSessionAdmin()),
        };
    }, [props.initialData]);

    return (
        <appModeDataStore.Provider initialData={initialData}>
            <AppModeController>{props.children}</AppModeController>
        </appModeDataStore.Provider>
    );
};

export default AppModeProvider;
