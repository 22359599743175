import { useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
    Alert,
    AlertIcon,
    Box,
    Button,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Stack,
} from '@chakra-ui/react';
import { useNotification } from '@pankod/refine-core';
import { useForm } from 'react-hook-form';

import {
    AppEvents,
    RhfMoneyInput,
    useLoadScripts,
    registerScriptResolver,
    useApiSdk,
} from 'ui-core';
import { paymentMethods } from '../contract-form/steps/step-payment';
import { ClearentCreditCard } from '../contract-form/payment-methods/clearent-credit-card';

export type PaymentFormModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

export const PaymentFormModal = (props: PaymentFormModalProps) => {
    const sdk = useApiSdk();
    const notification = useNotification();
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const form = useForm({
        defaultValues: {
            amount: '',
            customer_name: '',
            postal_code: '',
        },
    });
    const register = form.register;
    const errors = form.formState.errors;

    const configQuery = useQuery({
        queryKey: ['rtoConfig'],
        queryFn: async () => {
            const rtoConfig = await sdk.GetRtoConfig();
            return rtoConfig.rtoConfig;
        },
    });

    useMemo(() => {
        for (let method of paymentMethods) {
            if (method.onLoadScripts) {
                registerScriptResolver(method.id, method.onLoadScripts, 'payment');
            }
        }
    }, []);

    useLoadScripts('payment', configQuery.data);

    useEffect(() => {
        if (!props.isOpen) {
            form.reset();
        }
    }, [props.isOpen]);

    const paymentMethodResult = useRef<any>(null);

    const parentState = {
        paymentMethodResult: paymentMethodResult,
        enableAch: true,
    };

    const handleClickSubmit = async () => {
        setErrorMessage('');
        setIsLoading(true);

        try {
            await form.handleSubmit(async (formData) => {
                let eventResult = await AppEvents.do('before_submit_step', 'payment');

                if (eventResult instanceof Error) {
                    setErrorMessage(eventResult.message);
                    return;
                }

                if (!paymentMethodResult.current) {
                    setErrorMessage('Failed to submit payment.');
                    return;
                }

                let paymentResult = await sdk.ProcessPayment({
                    input: {
                        orderId: '',
                        method: paymentMethodResult.current.code,
                        metadata: {
                            ...formData,
                            ...paymentMethodResult.current,
                        },
                    },
                });

                if ('message' in paymentResult.processPayment) {
                    setErrorMessage(paymentResult.processPayment.message);
                } else {
                    notification.open &&
                        notification.open({
                            type: 'success',
                            message: 'Success',
                            description: 'Payment submitted',
                        });

                    props.onClose();
                }
            })();

            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
        }
    };

    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Make a Payment</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box mb={4}>
                        <form>
                            <Stack spacing={4}>
                                <FormControl isInvalid={!!errors.customer_name}>
                                    <FormLabel mb={1}>Customer Name</FormLabel>
                                    <Input
                                        {...register('customer_name', { required: 'Required.' })}
                                    />
                                    <FormErrorMessage>
                                        {errors.customer_name?.message}
                                    </FormErrorMessage>
                                </FormControl>
                                <Stack direction="row" spacing={4}>
                                    <FormControl isInvalid={!!errors.amount}>
                                        <FormLabel mb={1}>Amount</FormLabel>
                                        <RhfMoneyInput
                                            name="amount"
                                            control={form.control}
                                            rules={{ required: 'Required.' }}
                                        />
                                        <FormErrorMessage>
                                            {errors.amount?.message}
                                        </FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={!!errors.customer_name}>
                                        <FormLabel mb={1}>Zip Code</FormLabel>
                                        <Input
                                            {...register('postal_code', { required: 'Required.' })}
                                        />
                                        <FormErrorMessage>
                                            {errors.customer_name?.message}
                                        </FormErrorMessage>
                                    </FormControl>
                                </Stack>
                            </Stack>
                        </form>
                    </Box>
                    <Box mb={4}>
                        <ClearentCreditCard config={configQuery.data} parentState={parentState} />
                    </Box>
                    {errorMessage && (
                        <Alert status="error" borderRadius="md">
                            <AlertIcon />
                            {errorMessage}
                        </Alert>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button variant="ghost" onClick={props.onClose} mr={3}>
                        Cancel
                    </Button>
                    <Button onClick={handleClickSubmit} isLoading={isLoading}>
                        Submit Payment
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
