import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';

export type DashboardActionButtonProps = {
    label: string;
    icon: React.ReactElement;
} & ButtonProps;

export const DashboardActionButton = ({
    label,
    icon,
    height = '72px',
    ...rest
}: DashboardActionButtonProps) => {
    return (
        <Button
            variant="unstyled"
            leftIcon={icon}
            display="inline-flex"
            alignItems="center"
            minHeight={height}
            padding={4}
            borderRadius="md"
            border="1px"
            borderColor="gray.300"
            color="blue.500"
            _hover={{
                background: 'gray.100',
            }}
            {...rest}
        >
            {label}
        </Button>
    );
};

export default DashboardActionButton;
