import { MainSection } from 'ui-core';
import ActionsCard from './actions-card';

export const SellerDashboard = () => {
    return (
        <MainSection variant="form">
            <ActionsCard />
        </MainSection>
    );
};

export default SellerDashboard;
