export const INTEGRATION_FRAGMENT = /* GraphQL */ `
    fragment Integration on Integration {
        id
        createdAt
        updatedAt
        name
        user {
            identifier
        }
    }
`;

export const GET_INTEGRATION_LIST = /* GraphQL */ `
    query GetIntegrations($options: IntegrationListOptions) {
        integrations(options: $options) {
            items {
                ...Integration
            }
            totalItems
        }
    }
    ${INTEGRATION_FRAGMENT}
`;

export const GET_INTEGRATION = /* GraphQL */ `
    query GetIntegration($id: ID!) {
        integration(id: $id) {
            ...Integration
        }
    }
    ${INTEGRATION_FRAGMENT}
`;

export const CREATE_INTEGRATION = /* GraphQL */ `
    mutation CreateIntegration($input: CreateIntegrationInput!) {
        createIntegration(input: $input) {
            name
            secretKey
            user {
                identifier
            }
        }
    }
`;

export const UPDATE_INTEGRATION = /* GraphQL */ `
    mutation UpdateIntegration($input: UpdateIntegrationInput!) {
        updateIntegration(input: $input) {
            ...Integration
        }
    }
    ${INTEGRATION_FRAGMENT}
`;

export const DELETE_INTEGRATION = /* GraphQL */ `
    mutation DeleteIntegration($id: ID!) {
        deleteIntegration(id: $id) {
            result
            message
        }
    }
`;
