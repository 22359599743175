import {Checkbox, Create, Edit, FormControl, FormErrorMessage, FormLabel, Input } from "@pankod/refine-chakra-ui";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { useForm } from "@pankod/refine-react-hook-form";
import { MainSection, RhfPhoneInput } from "ui-core";

export const OrganizationEdit: React.FC<IResourceComponentsProps>  = () => {

    const {
        refineCore: { formLoading, queryResult },
        saveButtonProps,
        register,
        formState: { errors }, 
        control,     
    } = useForm();

    
    

return (
    <MainSection variant="form">
        <Edit title={"Edit " + queryResult?.data?.data.name} isLoading={formLoading} saveButtonProps={saveButtonProps} headerButtons>
            
            <Input 
                id="id"
                type="hidden"
                value={queryResult?.data?.data.id}
                {...register("id")}
            />
            <Input 
                id="name"
                type="hidden"
                value={queryResult?.data?.data.name}
                {...register("name")}
            />
                
                
            
            <FormControl isRequired mb={2}>
                <FormLabel>Email</FormLabel>
                <Input 
                    id="email"
                    placeholder="Email" 
                    type="email"
                    {...register("email", { required: "Email is required" })}
                />
                <FormErrorMessage>
                    {`${errors.title?.message}`}
                </FormErrorMessage>
            </FormControl>
            <FormControl isRequired mb={2}>
                <FormLabel>Phone</FormLabel>
                <RhfPhoneInput
                    id="phone"
                    name="phone"
                    control={control}
                    rules={{ required: 'Required.' }}
                        />
                
                <FormErrorMessage>
                    {`${errors.title?.message}`}
                </FormErrorMessage>
            </FormControl>
            <FormControl mb={1}>
                <FormLabel>Address</FormLabel>
                <Input 
                    id="address.street"
                    placeholder="Street" 
                    {...register("address.street")}
                />
                <FormErrorMessage>
                    {`${errors.title?.message}`}
                </FormErrorMessage>
            </FormControl>
            <FormControl mb={1}>
                
                <Input 
                    id="address.street2"
                    placeholder="Street 2" 
                    {...register("address.street2")}
                />
                <FormErrorMessage>
                    {`${errors.title?.message}`}
                </FormErrorMessage>
            </FormControl>
            <FormControl mb={1}>
                
                <Input 
                    id="address.city"
                    placeholder="City" 
                    {...register("address.city")}
                />
                <FormErrorMessage>
                    {`${errors.title?.message}`}
                </FormErrorMessage>
            </FormControl>
            <FormControl mb={1}>
                
                <Input 
                    id="address.state"
                    placeholder="State" 
                    {...register("address.state")}
                />
                <FormErrorMessage>
                    {`${errors.title?.message}`}
                </FormErrorMessage>
            </FormControl>
            <FormControl mb={1}>
                
                <Input 
                    id="address.postalCode"
                    placeholder="Zip"
                    type="number" 
                    {...register("address.postalCode")}
                />
                <FormErrorMessage>
                    {`${errors.title?.message}`}
                </FormErrorMessage>
            </FormControl>
            <FormControl mb={2}>
                
                <Input 
                    type="hidden"
                    id="address.country"
                    placeholder="Country"
                    value="USA" 
                    {...register("address.country")}
                />
                <FormErrorMessage>
                    {`${errors.title?.message}`}
                </FormErrorMessage>
            </FormControl>

            <FormControl mb={2}>
                <Checkbox id="settings.autoApprove" size='lg' colorScheme='green' defaultChecked={false}>
                    Auto-Approve Contracts
                </Checkbox>
                
            </FormControl>
            <Input 
                    id="settings"
                    type="hidden"
                    value="settings"
                    {...register("settings")}
                />
            
        </Edit>
    </MainSection>
);
};