import { useTable, flexRender } from "@pankod/refine-react-table";
import {
    List,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    HStack,
    Text,
    DateField,
    EditButton,
    DeleteButton,
    EmailField,
} from "@pankod/refine-chakra-ui";
import { ColumnFilter, ColumnSorter, MainSection, Pagination } from "ui-core";
import { useNavigation } from "@pankod/refine-core";


export const AdminList: React.FC = () => {
    const navMethods = useNavigation();

    const columns = [
       
        {
            id: 'firstName',
            header: 'First Name',
            accessorKey: 'firstName',
            meta: {
                filterOperator: "contains",
            },
        },
        {
            id: 'lastName',
            header: 'Last Name',
            accessorKey: 'lastName',
            meta: {
                filterOperator: "contains",
            },
        },
        {
            id: 'emailAddress',
            header: 'Email',
            accessorKey: 'emailAddress',
            meta: {
                filterOperator: "contains",
            },
            cell: function render({ getValue }: any) {
                return (
                    <EmailField value={getValue()} />
                );
            },
        },
        {
            id: 'role',
            header: 'Role',
            accessorKey: 'user',
            enableColumnFilter: false,
            cell: function render({ getValue }: any) {
                return (
                        getValue()?.roles[0]?.description
                );
            },
        },
        /*{
            id: 'createdAt',
            header: 'Created',
            accessorKey: 'createdAt',
            cell: function render({ getValue }: any) {
                return (
                    <DateField value={getValue() as string} format="LLL" />
                );
            },
            enableColumnFilter: false,
        },*/
    ];

    const {
        getHeaderGroups,
        getRowModel,
        setOptions,
        refineCore: {
            tableQueryResult: { data: tableData },
            setCurrent,
            pageCount,
            current,
           
        },
    } = useTable({
        columns,
    });
    
    setOptions((prev) => ({
        ...prev,
        meta: {
            ...prev.meta,
            
        },
    }));

    return (
        <MainSection>
        <List>
            <TableContainer whiteSpace="pre-line">
                <Table variant="simple" colorScheme="gray">
                    <Thead>
                        {getHeaderGroups().map((headerGroup) => (
                            <Tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <Th key={header.id}>
                                        {!header.isPlaceholder && (
                                            <HStack spacing="2">
                                                <Text>
                                                    {flexRender(
                                                        header.column.columnDef
                                                            .header,
                                                        header.getContext(),
                                                    )}
                                                </Text>
                                                <HStack spacing="2">
                                                    <ColumnSorter
                                                        column={header.column}
                                                    />
                                                    <ColumnFilter
                                                        column={header.column}
                                                    />
                                                </HStack>
                                            </HStack>
                                        )}
                                    </Th>
                                ))}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody>
                        {getRowModel().rows.map((row) => (
                            <Tr key={row.id}>
                                {row.getVisibleCells().map((cell) => (
                                    <Td 
                                        key={cell.id}
                                        onClick={()=> navMethods.edit("administrator", tableData?.data[row.id as any].id as any)}
                                        cursor="pointer"
                                    >
                                        {
                                           
                                            flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext(),
                                            )}
                                    </Td>
                                ))}
                                <Td>                            
                                    <DeleteButton
                                        hideText
                                        size="small"
                                        recordItemId={tableData?.data[row.id as any].id as any}
                                    />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
            <Pagination
                current={current}
                pageCount={pageCount}
                setCurrent={setCurrent}
            />
        </List>
        </MainSection>
       
    );
};