import { MutableRefObject, useEffect, useState } from 'react';
import {
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    FormControl,
} from '@chakra-ui/react';
import { Select, OptionBase } from 'chakra-react-select';
import { useApiSdk } from 'ui-core';

export type TargetProductTypesProps = {
    settingsRef: MutableRefObject<any>;
    initialState: string[];
};

interface ProductTypeOption extends OptionBase {
    label: string;
    value: string;
}

export const TargetProductTypes = (props: TargetProductTypesProps) => {
    const [productTypeOptions, setProductTypeOptions] = useState<ProductTypeOption[]>([]);
    const [selectedProductTypes, setSelectedProductTypes] = useState<readonly ProductTypeOption[]>(
        []
    );
    const sdk = useApiSdk();

    useEffect(() => {
        sdk.GetFacetList({
            options: {
                filter: {
                    code: {
                        eq: 'product_type',
                    },
                },
            },
        }).then((result) => {
            const productTypes = result.facets.items[0]?.values;
            if (productTypes) {
                setProductTypeOptions([
                    ...productTypes.map((value) => ({
                        label: value.name,
                        value: value.code,
                        colorScheme: 'blue',
                    })),
                ]);
            }
        });
    }, []);

    useEffect(() => {
        if (props.initialState) {
            setSelectedProductTypes(
                productTypeOptions.filter((opt) => props.initialState.includes(opt.value))
            );
        }
    }, [props.initialState, productTypeOptions]);

    useEffect(() => {
        if (props.settingsRef.current) {
            props.settingsRef.current.productTypes = selectedProductTypes.map((t) => t.value);
        }
    }, [selectedProductTypes]);

    return (
        <AccordionItem>
            <h2>
                <AccordionButton>
                    <Box as="span" flex="1" textAlign="left" color="green" fontWeight="bold">
                        Target Product Types
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
            </h2>
            <AccordionPanel pb={40}>
                <FormControl>
                    <Select
                        isMulti
                        options={productTypeOptions}
                        value={selectedProductTypes}
                        onChange={setSelectedProductTypes}
                    />
                </FormControl>
            </AccordionPanel>
        </AccordionItem>
    );
};

export default TargetProductTypes;
