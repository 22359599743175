import { Badge } from "@pankod/refine-chakra-ui";


const arrangingPayment = "ArrangingPayment";
const paymentSettled = "PaymentSettled";
const shipped = "Shipped";
const draft = "Draft";
const cancelled = "Cancelled";
const pendingSignature = "PendingSignature";
const pendingApproval = "PendingApproval";
const approved = "Approved";

export function getStatusString(code: string) {
    if (code === arrangingPayment) {
        return (
            <Badge size="xs" variant="outline" colorScheme="yellow">Pending Payment</Badge>
        );
    } else if (code === pendingApproval) {
        return (
            <Badge size="xs" variant="outline" colorScheme="yellow">Pending Approval</Badge>
        );
    } else if(code === approved) {
        return (
            <Badge size="xs" variant="outline" colorScheme="green">Accepted</Badge>
        );
    } else if(code === paymentSettled || code === shipped) {
        return (
            <Badge size="xs" variant="outline" colorScheme="green">Completed</Badge>
        );
    } else if(code === draft ) {
        return (
            <Badge size="xs" variant="outline" colorScheme="blue">Draft</Badge>
        );
    } else if(code === cancelled ) {
        return (
            <Badge size="xs" variant="outline" colorScheme="red">Cancelled</Badge>
        );
    }
    else if(code === pendingSignature ) {
        return (
            <Badge size="xs" variant="outline" colorScheme="orange">Pending Signature</Badge>
        );
    }

    return code;
}