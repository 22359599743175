import React from 'react';
import { LayoutProps } from '@pankod/refine-core';
import { Box, useColorModeValue } from '@pankod/refine-chakra-ui';

import { AppModeProvider } from '../../context/app-mode-provider';
import { Sider as DefaultSider } from '../sider';
import { Header as DefaultHeader } from '../header';

export * from './main-section';

export const Layout: React.FC<LayoutProps> = ({
    Sider,
    Header,
    Footer,
    OffLayoutArea,
    children,
}) => {
    const SiderToRender = Sider ?? DefaultSider;
    const HeaderToRender = Header ?? DefaultHeader;

    const bg = useColorModeValue('gray.100', 'gray.900');

    return (
        <AppModeProvider>
            <Box display="flex" bg={bg}>
                <SiderToRender />
                <Box display="flex" flexDirection="column" flex={1} overflow="hidden" minH="100vh">
                    <HeaderToRender />
                    {children}
                    {Footer && <Footer />}
                </Box>
                {OffLayoutArea && <OffLayoutArea />}
            </Box>
        </AppModeProvider>
    );
};
